import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { cloneDeep, flatten, isNumber } from "lodash";
import axios from "axios";
import { itineraryToItineraryInput } from "../utils/itineraryToItineraryInput";
import { useItineraryDuplicateTripProducts } from "./itineraryDuplicateTripProducts";
import { useShowError } from "../../Utils/showError";
import { useCircuitCorrespondingPricePeriod } from "../utils/circuitCorrespondingPricePeriod";
import { useFixedDateBlockCheck } from "./fixedDateBlockCheck";
import { getCircuit } from "../utils/getCircuit";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import GetCookie from "../../Common/Functions/GetCookie";
import { StepsDirectionsManager } from "../utils/stepsDirectionsManager";
import { StepsDatesManager } from "../utils/stepsDatesManager";
import { addBlock, markIndexAsCalculatingTransport } from "../redux/reducer";
import { SetCart } from "../../../Actions/Accommodation";
import { SetManualCart } from "../../../Actions/Cart";
import { SetFlightCart } from "../../../Actions/Flight";
import { SetCarsCart } from "../../../Actions/CarsSearch";
import { ConcatTransfersCart } from "../../../Actions/Transfers";
import { SetPoiCart } from "../../../Actions/Poi";
import { TripBlock } from "../objects/tripBlock";
import { SelectedRoom } from "../../ItineraryType/RoomTypePicker";
import { ItineraryState } from "../objects/itineraryState";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (
    options: {
        tripId: number,
        tripVersion: number,
        steps: number[],
        index: number,
        dryRun?: boolean
    } & ({
        type: 'circuit',
        circuitId: number | null,
        circuitVersion: number | null,
        circuitVariant: number,
        circuitDate: string,
        circuit: ItineraryState['blocks']['circuits']['cache'][number][number],
        products?: number[],
        adalteRooms?: SelectedRoom[],
        travelExchangeOptions?: {
            selectedTravelExchangePrices: any,
            selectedTravelExchangeOptions: any,
            mealPlan: any
        }
    } | {
        type: 'typical-trip',
        trip: TripBlock
    })
) => Promise<boolean | { startDate: string }>

export function useItineraryBlockAdd(): Callback {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const stepInputs = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const steps = useMemo(() => {
        return stepInputs.filter((item) => {
            return ['START', 'STEP'].includes(item.step_type);
        });
    }, [stepInputs]);
    const dupliateTripProducts = useItineraryDuplicateTripProducts({});
    const getCorrespondingPricePeriod = useCircuitCorrespondingPricePeriod();
    const checkForFixedDateBlock = useFixedDateBlockCheck();
    const showError = useShowError();

    return useCallback(
        async (options) => {
            let block = (
                options.type === 'circuit' ?
                    options.circuit.steps :
                    options.trip.data[0]?.steps ?? []
            ).filter((step) => {
                return step.step_type === 'STEP';
            }).filter((item) => {
                return options.steps.includes(item.id);
            }).map((item) => {
                return {
                    ...itineraryToItineraryInput(item),
                    circuit: options.type !== 'typical-trip' ?
                        options.circuitId :
                        null,
                    circuit_trip_version: options.type !== 'typical-trip' ?
                        options.circuitVersion :
                        null,
                    iti_type: options.type === 'typical-trip' ?
                        options.tripId :
                        null
                };
            });
            if (block[0] && tripStartDate && tripEndDate) {
                const manager = StepsDirectionsManager.getInstance();
                const datesManager = new StepsDatesManager(
                    tripStartDate,
                    tripEndDate
                );


                dispatch(
                    markIndexAsCalculatingTransport({
                        index: options.index - 1,
                        isCalculating: true
                    })
                );

                const startDate = window.moment.utc(tripStartDate).set({
                    hour: 9,
                    minute: 0,
                    second: 0
                });
                let lastStep = cloneDeep(steps[options.index - 1]);
                let belowBlockStep = cloneDeep(steps[options.index]);

                const delta = window.moment.utc(block[0].end_date).startOf('day').diff(window.moment.utc(block[0].start_date).startOf('day'), "days");
                block[0].start_date = startDate.toISOString();
                block[0].end_date = window.moment.utc(block[0].start_date).add(delta, "days").toISOString();
                // block[0].end_date = startDate.toISOString();
                block[0].circuit_start_date = options.type === 'circuit' && options.circuitDate !== 'PERIOD' ?
                    options.circuitDate :
                    null;
                for (let i = 0; i < block.length; i++) {
                    let current = cloneDeep(block[i]!);
                    const next = cloneDeep(block[i + 1]);

                    if (
                        i === 0 &&
                        lastStep
                    ) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            lastStep,
                            current,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        lastStep = resultPair[0];
                        block[0] = current = resultPair[1];
                    }

                    if (
                        i === block.length - 1 &&
                        belowBlockStep
                    ) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            current,
                            belowBlockStep,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        block[i] = current = resultPair[0];
                        belowBlockStep = resultPair[1];
                    }

                    if (next) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            current,
                            next,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        block[i] = resultPair[0];
                        block[i + 1] = resultPair[1];
                    }
                }

                const firstBlockStep = block[0];
                const lastBlockStep = block[block.length - 1];

                const { pass_check, headers } = CheckBeforeRequest();

                // check periods if it is a circuit / package
                if (options.type === 'circuit' && pass_check && isNumber(options.circuitId)) {
                    try {
                        let periods = options.circuit.periods ?? [];
                        const periodIds = flatten(
                            options.circuit?.priceTerrestrial?.map((item) => {
                                return item.periods;
                            }).concat(
                                options.circuit.priceFlight?.map((item) => {
                                    return item.periods;
                                }) ?? []
                            )
                        );
                        periods = periods.filter((period) => {
                            return periodIds.includes(period.id);
                        });
                        const circuit = await getCircuit(options.circuitId);
                        if (
                            circuit?.forfait_price === false &&
                            (
                                options.circuitDate !== 'PERIOD' ||
                                periods.length > 0
                            )
                        ) {
                            const period = await getCorrespondingPricePeriod({
                                circuitId: options.circuitId,
                                circuitVersion: options.circuitVersion,
                                circuitVariants: [options.circuitVariant],
                                circuitDate: options.circuitDate,
                                periods,
                                step: firstBlockStep,
                                block
                            });

                            if (!period && periods.length > 0) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }

                            if (
                                lastBlockStep &&
                                belowBlockStep &&
                                checkForFixedDateBlock({
                                    aboveStep: lastBlockStep,
                                    step: lastBlockStep,
                                    belowStep: belowBlockStep
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-incompatible-blocks-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }

                            if (
                                firstBlockStep &&
                                lastStep &&
                                checkForFixedDateBlock({
                                    aboveStep: lastStep,
                                    step: firstBlockStep,
                                    belowStep: firstBlockStep
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }
                        }
                    } catch (error) {
                        showError(error as Error);
                        return false;
                    }
                }

                if (options.dryRun) {
                    dispatch(
                        markIndexAsCalculatingTransport({
                            index: options.index - 1,
                            isCalculating: false
                        })
                    );
                    return { startDate: firstBlockStep.start_date };
                }

                const version = parseInt(GetCookie('trip_id_version') ?? '-1');
                try {
                    await dupliateTripProducts({
                        sourceTripId: options.tripId,
                        sourceTripVersion: options.tripVersion,
                        startDate: firstBlockStep.start_date,
                        targetTripVersion: version,
                        products: options.type === 'circuit' ? options.products : undefined,
                        adalteRooms: options.type === 'circuit' ? options.adalteRooms : undefined,
                        travelExchangeOptions: options.type === 'circuit' ? options.travelExchangeOptions : undefined
                    });
                } catch (error) {
                    showError(error as Error);
                    return false;
                } finally {
                    dispatch(
                        markIndexAsCalculatingTransport({
                            index: options.index - 1,
                            isCalculating: false
                        })
                    );
                }

                if (pass_check) {
                    let request_product = [];
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/custom-products/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/manual-products/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/flight/`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/`
                        })
                    );
                    axios.all([...request_product]).then(axios.spread((...responses) => {
                        responses.forEach((response) => {
                            if (response.config.url?.includes("accommodation")) {
                                dispatch(SetCart(response.data));
                                response.data.map((room: any) => {
                                    if (room.booking_status !== null && room.booking_status.confirmation_reference === null && room.provider === 121) {
                                        axios({
                                            method: 'POST',
                                            headers: headers,
                                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${room.product_type}`,
                                            data: {
                                                items: [room.id]
                                            }
                                        }).then(response => {
                                            dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                                        }).catch(error => {
                                            console.log(error);
                                        });
                                    }
                                });
                            }
                            if (response.config.url?.includes("manual-products")) {
                                if (response.data.length > 0) {
                                    let products: any[] = [];
                                    response.data.map((product: any) => {
                                        if (Object.keys(product).length > 0) {
                                            products.push(product);
                                        }
                                    });
                                    dispatch(SetManualCart(products));
                                }
                            }
                            if (response.config.url?.includes("flight")) {
                                if (response.data.length > 0) {
                                    let arr = CheckResponse(response.data, tripEndDate);
                                    dispatch(SetFlightCart(arr));
                                }
                            }
                            if (response.config.url?.includes("car")) {
                                let cars_data = response.data;
                                if (response.data.length > 0) {
                                    cars_data.map((item: any) => {
                                        item.detail = false;
                                        item.charges.sort((a: any, b: any) => {
                                            //eslint-disable-next-line no-nested-ternary
                                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                                        });
                                    });
                                    dispatch(SetCarsCart(cars_data));
                                }
                            }

                            if (response.config.url?.includes("transfers")) {
                                dispatch(ConcatTransfersCart(response.data));
                            }
                            if (response.config.url?.includes("custom-products")) {
                                let tmp_poi: any[] = [];
                                let tmp_transfers: any[] = [];
                                response.data.map((el: any) => {
                                    if (el.product_type === 4) {
                                        tmp_transfers.push(el);
                                    } else {
                                        tmp_poi.push(el);
                                    }
                                });
                                dispatch(SetPoiCart(tmp_poi));
                                dispatch(ConcatTransfersCart(tmp_transfers));
                            }
                            if (response.config.url?.includes("assistance")) {
                                dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data });
                            }
                        });
                    }));
                }

                dispatch(
                    addBlock({
                        block,
                        variant: options.type === 'circuit' ?
                            options.circuitVariant :
                            null,
                        date: options.type === 'circuit' ?
                            options.circuitDate :
                            'PERIOD',
                        startDate: steps.length > 0 ?
                            firstBlockStep.start_date :
                            tripStartDate,
                        index: options.index
                    })
                );

                return true;
            }

            return false;
        },
        [
            t,
            dispatch,
            enqueueSnackbar,
            tripStartDate,
            tripEndDate,
            steps,
            getCorrespondingPricePeriod
        ]
    );
}
