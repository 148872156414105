import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { StaticDateRangePicker, LocalizationProvider, PickersDay } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";

import DatesChange from "./Functions/DatesChange";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/material";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRight: 0,
        borderTopLeftRadius: "20px",
        borderTopRightRadius: 0,
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: 0,
        padding: '6px 13px',
        position: "relative",
        textTransform: "capitalize",
        height: 41
  	},
    datepickerStyle: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)',
            [theme.breakpoints.between(768, 1440)]: {
		      	fontSize: '14px'
		    }
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 20px',
            borderRadius: '0',
            textAlign: "center",
            cursor: "pointer"
        }
    },
    datepickerStyleLeft: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: "20px 0 0 20px"
        }
    },
    datepickerStyleRight: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: "0 20px 20px 0"
        }
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        padding: "16px 32px !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    buttonWhite: {
        backgroundColor: 'white'
    },
    responsiveSize: {
        fontSize: 10
    },
    highlight: {
        "borderRadius": "50%",
        //opacity: '30%',
        "backgroundColor": theme.palette.primary.main,
        "color": "white !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    selectedDate: {
        "borderRadius": "50%",
        //opacity: '30%',
        "backgroundColor": "#ee7203 !important",
        "color": "white !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    betweenStartEnd: {
        '&:hover': {
            border: '1px dashed',
            borderRight: 'none'
        }
    }
}));

const HeaderDatePicker = ({ setIsOpen, isOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const accommodation_cart = useSelector((store) => store.accommodation.cart);
    const car_cart = useSelector((store) => store.cars_search.cart);
    const flight_cart = useSelector((store) => store.flight.cart);
    const manual_item_list = useSelector((store) => store.cart.manual_item_list);
    const trip_start_date = useSelector((store) => store.trip.start_date);
    const trip_end_date = useSelector((store) => store.trip.end_date);
    const language = useSelector(store => store.header.language);
    const all_data = useSelector((store) => store.trip.all_data);
    const data_trip = useSelector((store) => store.trip.data_trip);
    const product_period = useSelector((store) => store.trip.product_period);
    const user = useSelector(store => store.user.user);
    const itinerary = useSelector(store => store.itinerary.itinerary_list);

    const isDisabled = quotation_code === "cercledesvoyages" ? (data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV") : false;

    const inputRefStart = useRef(null);
    const inputRefEnd = useRef(null);

    const [value, setValue] = useState([trip_start_date !== null ? moment.utc(trip_start_date) : new Date(), trip_end_date !== null ? moment.utc(trip_end_date) : new Date()]);
    const [datePickerState, setDatePickerState] = useState("start");
    const periods = product_period.find(period => period.id === all_data.circuit_period_id);
    moment.locale(language);
    useEffect(() => {
        if (isOpen === "datepicker" && datePickerState === "start") {
            inputRefStart.current.focus();
        } else if (isOpen === "datepicker" && datePickerState === "end") {
            inputRefEnd.current.focus();
        }
    }, [datePickerState, isOpen]);
    useEffect(() => {
        if (trip_start_date !== null && trip_end_date !== null) {
            setValue([moment.utc(trip_start_date), moment.utc(trip_end_date)]);
        }
    }, [trip_start_date, trip_end_date]);
    const onClose = () => {
        setValue([trip_start_date !== null ? moment.utc(trip_start_date) : new Date(), trip_end_date !== null ? moment.utc(trip_end_date) : new Date()]);
        handleOpen();
    };
    const handleOpen = () => {
        if (!isDisabled) {
            if (isOpen === null || isOpen !== "datepicker") {
                setIsOpen("datepicker");
            } else {
                setIsOpen(null);
            }
        } else {
            enqueueSnackbar(t("header.warning_amendment"), { variant: "warning" });
        }
    };
    const renderWeekPickerDay = (date, Props) => {
        let dayIsBetween = false;
        let no_start_date = false;
        let between_start_end = false;
 	    const dateClone = moment(date);
        product_period.map((period) => {
            if (period.start_date === null || period.end_date === null) {
                no_start_date = true;
            } else if (moment.utc(dateClone).isSameOrAfter(moment.utc(period.start_date), 'd') && moment.utc(dateClone).isSameOrAfter(moment.utc(), 'd') && moment.utc(dateClone).isSameOrBefore(moment.utc(period.end_date), 'd') && period.start_days !== undefined && (period.start_days.length === 0 || period.start_days.includes(moment(dateClone).weekday()))) {
                dayIsBetween = true;
            }
        });


        // if (periods !== undefined) {
        //     if (periods.start_date === null || periods.end_date === null) {
        //         no_start_date = true;
        //     } else if (moment(dateClone).isSameOrAfter(moment(periods.start_date)) && moment(dateClone).isSameOrAfter(moment()) && moment(dateClone).isSameOrBefore(moment(periods.end_date)) && periods.start_days !== undefined && (periods.start_days.length === 0 || periods.start_days.includes(moment(dateClone).weekday()))) {
        //         dayIsBetween = true;
        //     }
        // }
        if (moment(dateClone).isSameOrAfter(moment(trip_start_date)) && moment(dateClone).isSameOrBefore(moment(trip_end_date)) ) {
            between_start_end = true;
        }
        return (
            // <Stack className={clsx({
            //     [classes.betweenStartEnd]: between_start_end
            // })}>
                <PickersDay
                    {...Props}
                    //disableMargin
                    // disabled={!dayIsBetween}
                    className={clsx({
                        //[classes.bold]: dayIsBetween,
                        [classes.highlight]: dayIsBetween,
                        [classes.selectedDate]: dateClone.isSame(value[0], 'day') || dateClone.isSame(value[1], 'day'),
                    })}
                />
            // </Stack>
        );
    }
    return (
        <Fragment>
            {
                !isDisabled && (
                    <Button onClick={ handleOpen } variant={"outlined"} fullWidth className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} disableRipple={true}>
                        {moment.utc(trip_start_date).format(matches ? "DD/MM" : "DD MMM") + " - " + moment.utc(trip_end_date).format(matches ? "DD/MM" : "DD MMM")}
                    </Button>
                )
            }
            {
                isDisabled && (
                    <Tooltip title={t("header.warning_amendment")}>
                        <Button onClick={ handleOpen } variant={"outlined"} fullWidth className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} disableRipple={true}>
                            {moment.utc(trip_start_date).format(matches ? "DD/MM" : "DD MMM") + " - " + moment.utc(trip_end_date).format(matches ? "DD/MM" : "DD MMM")}
                        </Button>
                    </Tooltip>
                )
            }
            {
                isOpen === "datepicker" && (
                    <ClickAwayListener onClickAway={ onClose }>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <Fragment>
                                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                                        <DialogTitle>
                                            <Tooltip title={t("header.departure_date_tooltip")}>
                                                <TextField
                                                    className={`${classes.datepickerStyle} ${classes.datepickerStyleLeft}`}
                                                    label={""}
                                                    variant={"outlined"}
                                                    helperText={""}
                                                    value={value[0] !== null ? moment.utc(value[0]).format("L") : moment.utc(value[1]).format("L")}
                                                    onClick={() => {
                                                        setDatePickerState("start");
                                                    }}
                                                    inputRef={inputRefStart}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title={t("header.arrival_date_tooltip")}>
                                                <TextField
                                                    className={`${classes.datepickerStyle} ${classes.datepickerStyleRight}`}
                                                    label={""}
                                                    variant={"outlined"}
                                                    helperText={""}
                                                    value={value[1] !== null ? moment.utc(value[1]).format("L") : moment.utc(value[0]).format("L")}
                                                    onClick={() => {
                                                        setDatePickerState("end");
                                                    }}
                                                    inputRef={inputRefEnd}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Tooltip>
                                        </DialogTitle>
                                        <StaticDateRangePicker
                                            startText={t("header.responsive.start")}
                                            endText={t("header.responsive.end")}
                                            displayStaticWrapperAs="desktop"
                                            value={value}
                                            open={true}
                                            renderDay={periods !== undefined ? renderWeekPickerDay : undefined}
                                            onChange={(newValue) => {
                                                if (newValue[0].format('YYYY-MM-DD') !== value[0].format('YYYY-MM-DD')) {
                                                    let tmp_value = [...newValue];
                                                    let diff = moment(trip_end_date).diff(moment(trip_start_date), 'days')
                                                    tmp_value[1] = moment(tmp_value[0]).add(diff, 'days');
                                                    newValue = tmp_value;
                                                }
                                                setValue(newValue);
                                            }}
                                            currentlySelectingRangeEnd={datePickerState}
                                            setCurrentlySelectingRangeEnd={(newValue) => {
                                                setDatePickerState(newValue);
                                            }}
                                            variant={"inline"}
                                            renderInput={(startProps, endProps) => (
                                                <Fragment>
                                                    <TextField
                                                        {...startProps}
                                                        className={`${classes.datepickerStyle}`}
                                                        label={""}
                                                        variant={"outlined"}
                                                        helperText={""}
                                                    />
                                                    <TextField
                                                        {...endProps}
                                                        className={`${classes.datepickerStyle}`}
                                                        label={""}
                                                        variant={"outlined"}
                                                        helperText={""}
                                                    />
                                                </Fragment>
                                            )}
                                        />
                                        <DialogActions>
                                            <Button onClick={ onClose }>{t("accommodation.cancel")}</Button>
                                            <Button variant={"contained"} className={ classes.orangeButton } onClick={() => {
                                                DatesChange(accommodation_cart, car_cart, flight_cart, manual_item_list, trip_start_date, trip_end_date, { startDate: value[0], endDate: value[1] }, itinerary, user, dispatch, t, enqueueSnackbar);
                                                handleOpen();
                                            }}>{t("global.validate")}</Button>
                                        </DialogActions>
                                    </LocalizationProvider>
                                </Fragment>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};
export default HeaderDatePicker;
