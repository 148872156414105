import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from "axios";
import clsx from "clsx";

import Box from '@mui/material/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FlightIcon from '@material-ui/icons/Flight';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import green from "@material-ui/core/colors/green";

import { FormGroup } from '@mui/material';

import FillMultiDest from "../../Common/Functions/FillMultiDest";
import CheckGroups from "../Functions/CheckGroups";
import ManualRequestSearch from "../Functions/ManualRequestSearch";
import GetMinimalPrices from "../Functions/GetMinimalPrices";

import SearchFlightBar from "./SearchFlightBar";
import ChoosePassenger from "./ChoosePassenger";
import AirlineSearch from "./AirlineSearch";
import SearchHistory from "./SearchHistory";
import AlreadyInCart from "./AlreadyInCart";
import AcceptPublicPrice from "./AcceptPublicPrice";

import {
    GetProvider, SetJourneyType, AddJourneyToflight, SetOnewayJourney, ResetError, SetDirectFlight,
    SetIncludedLuggage, SetFlexibleDate, SetPublicPrice, SetPrivatePrice, AddFlightGroup, DeleteFlightGroupsAtIndex,
    SetGroupIndex, SetGroupCopyIndex, SetGroupsManualRequest, SaveFlightGroups, SetOpenDatePickers, SelectProvider, SetManualLuggage
} from "../../../Actions/Flight";
import { SetState, SetFetchedDataStatus, SetFetchedMatrixStatus, CreateStopoverDuration, CreateFlightHours, SetShowMoreResults, ResetProvider, InitStopOverActive } from "../../../Actions/FlightSearch";

import moment from "moment";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import PnrSearch from "./PnrSearch";
import { useShowError } from "../../Utils/showError";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 700,
        // color: '#0000008A',
        letterSpacing: 1.25,
        textTransform: "uppercase"
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize24: {
        fontSize: 24
    },
    bold: {
        fontWeight: "bold"
    },
    underline: {
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    genericIcon: {
        padding: "8px 4px 4px 4px",
        color: '#0000008A'
    },
    genericPadding: {
        padding: "12px 30px"
    },
    spacer: {
        padding: 12
    },
    verticalSpacer: {
        padding: "12px 0px"
    },
    formShadow: {
        borderRadius: 4
        // boxShadow: '0 1px 6px rgba(32,33,36,0.28)'
    },
    searchButton: {
        backgroundColor: `${main_color}`,
        border: `2px solid ${main_color}`,
        color: "white",
        padding: "8px 80px"
    },
    searchButtonProgress: {
        padding: "8px 80px"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    cancelButton: {
        border: `2px solid ${main_color}`,
        background: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: 4,
        padding: "8px 80px"
    },
    cancelButtonText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: `${main_color}`,
        letterSpacing: 1.25,
        fontWeight: 500,
        fontSize: 14,
        textTransform: "uppercase"
    },
    formControlRoot: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            zIndex: 1
        }
    },
    textFieldInput: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        color: '#0000008A',
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: "0px 8px 8px 0px"
    },
    circleBorder: {
        borderRadius: 8
    },
    heightManualReq: {
        height: 75
    },
    withBorder: {
        borderRadius: "0px 8px 8px 0px"
    },
    startAdornment: {
        marginRight: 12
    },
    container: {
        background: '#F3F6FC',
        border: '1px solid #F3F6FC'
        // background: '#bdbdbd10'
    },
    checkbox: {
        '&:not(.Mui-checked) .PrivateSwitchBase-input': {
            "width": 'auto',
            "height": 'auto',
            "top": 'auto',
            "left": 'auto',
            "opacity": '1',
            "visibility": 'hidden',
            '&::before': {
                content: '""',
                position: 'absolute',
                background: 'white',
                height: "100%",
                width: "100%",
                visibility: "visible"
            }
        }
    },
    radio: {
        '&:not(.Mui-checked) .PrivateSwitchBase-input': {
            "width": 'auto',
            "height": 'auto',
            "top": 'auto',
            "left": 'auto',
            "opacity": '1',
            "visibility": 'hidden',
            '&::before': {
                content: '""',
                position: 'absolute',
                background: 'white',
                height: "100%",
                width: "100%",
                visibility: "visible",
                borderRadius: '50%'
            }
        }
    }
}));

// const SortableGrid = SortableContainer(({ children }) => {
//     return <Grid>{children}</Grid>;
// });

let source = null;

const FlightGroups = withRouter(({ router, origin_url, display, setDisplay, setSearchGroup, use_cache, setLoadingRequest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const language = useSelector(store => store.header.language);
    const itinerary_list = useSelector(store => store.itinerary.itinerary_list);
    const departure_destination = useSelector(store => store.itinerary.departure_destination);
    const return_destination = useSelector(store => store.itinerary.return_destination);
    const start_date = useSelector(store => store.trip.store_date);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_id = useSelector(store => store.trip.trip_id);
    const all_data = useSelector(store => store.trip.all_data);
    const trip_currency = useSelector(store => store.trip.currency);
    const flight_cart = useSelector(store => store.flight.cart);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const flight_providers = useSelector(store => store.flight.provider_list);
    const multi_dest_filled = useSelector(store => store.flight.multi_dest_filled);
    const open_date_pickers = useSelector(store => store.flight.open_date_pickers);
    const index_group = useSelector(store => store.flight.index_group);
    const provider_list = useSelector(store => store.trip.providers);
    const filter_time = useSelector(store => store.flight_search.filter_time);
    const stopover_duration = useSelector(store => store.flight_search.stopover_duration);
    const user = useSelector(store => store.user.user);
    const flight_param = useSelector(store => store.itinerary_type.flight_circuit);

    const [manualRequest, setManualRequest] = useState([""]);
    const [alreadyInCart, setAlreadyInCart] = useState(false);
    const [cartDate, setCartDate] = useState(null);
    const [cancelSearch, setCancelSearch] = useState(null);
    const [manualSearching, setManualSearching] = useState(false);
    const [journeyUpdate, setJourneyUpdate] = useState(false);
    //only for visiteurs
    const [agencyPublicPrice, setAgencyPublicPrice] = useState(false);
    const [agencyPublicPriceGroup, setAgencyPublicPriceGroup] = useState(0);

    const manualReqLabel = useRef(null);
    const manualReqLabelWidth = manualReqLabel.current ? manualReqLabel.current.clientWidth : 130;

    const selectProviderLabel = useRef(null);
    const showError = useShowError();
    const selectProviderLabelWidth = selectProviderLabel.current ? selectProviderLabel.current.clientWidth : 130;

    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    moment.locale(language);

    useEffect(() => {
        let temp_manual_request = [];
        if (origin_url === "groups") {
            for (let i = 0; i < itinerary_list.length; i++) {
                if (itinerary_list[i].step_type === 'STEP') {
                    FillMultiDest(itinerary_list, 2, start_date, dispatch, departure_destination, return_destination, t);
                    break;
                }
            }
        }
        flight_groups.map((group) => {
            temp_manual_request.push(group.manual_request);
        });

        setManualRequest(temp_manual_request);
    }, []);

    useEffect(() => {
        if (manualSearching) {
            manualRequest.map((manual_request, group_index) => {
                dispatch(SetGroupsManualRequest(group_index, manual_request));
            });
            if (flight_groups[index_group].journey_type === 4) {
                ManualRequestSearch(manualRequest, flight_groups, flight_providers, index_group, dispatch, enqueueSnackbar, end_date, t, setManualSearching, router);
            } else {
                PnrSearch(manualRequest, flight_groups, flight_providers, index_group, dispatch, enqueueSnackbar, end_date, t, setManualSearching, router, showError);
            }
        }
    }, [manualSearching]);

    useEffect(() => {
        if (journeyUpdate) {
            flight_groups.map((group, group_index) => {
                getProviders(group_index);
            });
            setJourneyUpdate(false);
        }
    }, [journeyUpdate]);
    useEffect(() => {
        if (cancelSearch === false) {
            launchSearch();
            setCancelSearch(null);
        }
    }, [cancelSearch]);

    useEffect(() => {
        if (multi_dest_filled) {
            let temp_open_date_pickers = [];
            flight_groups.map((group) => {
                let temp_group_pickers = [];
                for (let i = 0; i < group.journey.length; i++) {
                    temp_group_pickers.push(false);
                }
                temp_open_date_pickers.push(temp_group_pickers);
            });
            dispatch(SetOpenDatePickers(temp_open_date_pickers));
        }
    }, [multi_dest_filled]);

    useEffect(() => {
        if (flight_groups.length !== 0 && origin_url === 'groups') {
            flight_groups.map((group, group_index) => {
                getProviders(group_index);
            });
            if (flight_param.length !== 0) {
                console.log('flight_param:', flight_param);
                let gir_provider = null;
                if (flight_param[0].allow_manual_flight) {
                    gir_provider = provider_list.find((provider) => {
                        return provider.provider.code === "custom_flight";
                    });
                }
                console.log('gir_provider:', gir_provider);
                dispatch({ type: "FLIGHT_SET_PARAMS", payload: { group_index: 0, params: flight_param[0], gir_provider: gir_provider ? gir_provider.provider : null } });
            }
        }
    }, [flight_groups.length]);
    useEffect(() => {
        if (quotation_code === "visiteurs" && user.client_full.type === 2) {
            flight_groups.map((group, group_index) => {
                if (group.journey_type === 3) {
                    group.journey.map((journey, journey_index) => {
                        if (journey.check_destination) {
                            if (journey.origin !== null && journey.destination !== null && journey.origin.iata_city.iata_country.id === journey.destination.iata_city.iata_country.id) {
                                setAgencyPublicPriceGroup(group_index);
                                setAgencyPublicPrice(true);
                                dispatch({type: 'FLIGHT_SET_JOURNEY_INDEX', payload: journey_index});
                            } else {
                                dispatch({type: 'FLIGHT_CHECK_DESTINATION', payload: {group_index: group_index, check_destination: false, journey_index: journey_index}});
                            }
                        }
                    });
                }
            });
        }
    }, [flight_groups]);
    const getProviders = (group_index) => {
        let flight_providers = [];
        provider_list.map((provider) => {
            if (provider.enabled_content_types.includes(6) && all_data.branch_code === provider.branch_code) {
                console.log('flight_groups:', flight_groups);
                if (flight_groups[group_index].journey_type !== 5) {
                    if (["eva_amadeus", "eva_misterfly", "amadeus_rest", "1G", "tahitinui", "amadeus_soap", "sabre_rest", "custom_flight", "travel_fusion"].includes(provider.provider.code)) {
                        flight_providers.push({
                            id: provider.provider.id,
                            name: provider.provider.name,
                            code: provider.provider.code,
                            created_date: provider.provider.created_date
                        });
                    }
                    //if (quotation_code === "verdie") {
                    //flight_providers.map((provider, provider_index) => {
                    //if (provider.name === "eva_amadeus" && provider_index !== 0){
                    //let temp_provider = flight_providers[0];
                    //// put desired provider in first position
                    //flight_providers[0] = provider;
                    //}
                    //})
                    //}
                } else if (["eva_amadeus", "amadeus_rest", "1G", "amadeus_soap", "travel_fusion", "sabre_rest"].includes(provider.provider.code)) {
                    flight_providers.push({
                        id: provider.provider.id,
                        name: provider.provider.name,
                        code: provider.provider.code,
                        created_date: provider.provider.created_date
                    });
                }
            }
        });

        dispatch(GetProvider(flight_providers, group_index, quotation_code));
    };

    const handleJourneyType = (value, group_index) => {
        let newValue = parseInt(value);
        if (newValue === 1 || newValue === 2) {
            if (flight_groups[group_index].journey.length > 1) {
                dispatch(SetOnewayJourney(group_index));
            }
            let inbound_journey = flight_groups[group_index].journey[0];
            let filter_step = itinerary_list.filter(iti => iti.step_type === 'STEP');
            let last_iti_step = filter_step[filter_step.length - 1];
            //reverse itinerary for outbound journey
            let return_journey = {
                class_type: inbound_journey.class_type,
                destination: inbound_journey.origin,
                origin: inbound_journey.destination,
                start_date: last_iti_step.end_date
            };
            dispatch(AddJourneyToflight(return_journey, group_index));
            if (stopover_duration !== null && stopover_duration.length < 2) {
                dispatch(CreateStopoverDuration(2));
            }
            if (filter_time !== null && filter_time.length < 2) {
                dispatch(CreateFlightHours(2));
            }
            let temp_open_date_pickers = open_date_pickers.slice();
            temp_open_date_pickers[group_index] = [false, false];
            dispatch(SetOpenDatePickers(temp_open_date_pickers));
            dispatch(SetManualLuggage(group_index, null));
        } else if (newValue === 3) {
            dispatch(SetOnewayJourney(group_index));
            let temp_open_date_pickers = open_date_pickers.slice();
            temp_open_date_pickers[group_index] = [false];
            dispatch(SetOpenDatePickers(temp_open_date_pickers));
            dispatch(SetManualLuggage(group_index, null));
        } else if (newValue === 4) {
            dispatch(SetManualLuggage(group_index, false));
        } else if (newValue === 5) {
            dispatch(SetManualLuggage(group_index, null));
        }
        dispatch(ResetError(group_index));
        dispatch(SetJourneyType(newValue, group_index));
        setJourneyUpdate(true);
    };

    const addFlightMultiDest = (group_index) => {
        if (flight_groups[group_index].journey.length < 9) {
            let last_old_flight = flight_groups[group_index].journey.slice(-1)[0];
            let newJourney = {
                class_type: 0,
                origin: last_old_flight.destination,
                destination: null,
                start_date: last_old_flight.start_date
            };
            let temp_open_date_pickers = open_date_pickers.slice();
            let temp_open_date_pickers_group = [];
            for (let i = 0; i < flight_groups[group_index].journey.length + 1; i++) {
                temp_open_date_pickers_group.push(false);
            }
            temp_open_date_pickers[group_index] = temp_open_date_pickers_group;
            dispatch(SetOpenDatePickers(temp_open_date_pickers));

            dispatch(CreateStopoverDuration(flight_groups[group_index].journey.length + 1));
            dispatch(CreateFlightHours(flight_groups[group_index].journey.length + 1));
            dispatch(AddJourneyToflight(newJourney, group_index));
        } else {
            enqueueSnackbar(t("flight_groups.warning.multi_dest"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 3000 });
        }
    };

    const handleDirectFlight = (group_index, direct_flight) => {
        dispatch(SetDirectFlight(group_index, { direct_flight: !direct_flight, one_stop: false }));
    };

    const handleOneStop = (group_index, one_stop) => {
        dispatch(SetDirectFlight(group_index, { direct_flight: false, one_stop: !one_stop }));
    };

    const handleIncludedLuggage = (group_index, included_luggage) => {
        dispatch(SetIncludedLuggage(group_index, !included_luggage));
    };

    const handleFlexibleDate = (group_index, flexible_date) => {
        dispatch(SetFlexibleDate(group_index, !flexible_date));
        dispatch(SetShowMoreResults(flexible_date ? null : false));
    };

    const handlePublicPrice = (group_index, public_price) => {
        if (!public_price && quotation_code === "visiteurs" && user.client_full.type === 2) {
            setAgencyPublicPriceGroup(group_index);
            setAgencyPublicPrice(true);
        } else {
            dispatch(SetPublicPrice(group_index, !public_price));
        }
    };
    const handleStandardPrice = (group_index, standard_price) => {
        dispatch({ type: "FLIGHT_SET_STANDARD_PRICE", payload: { group_index: group_index, standard_price: !standard_price } });
    };
    const handleNDCPrice = (group_index, ndc_price) => {
        dispatch({ type: "FLIGHT_SET_NDC_PRICE", payload: { group_index: group_index, ndc_price: !ndc_price } });
    };
    const handlePrivatePrice = (group_index, private_price) => {
        dispatch(SetPrivatePrice(group_index, !private_price));
    };
    const handlePrivatePriceNDC = (group_index, private_price_ndc) => {
        dispatch({ type: "FLIGHT_SET_PRIVATE_PRICE_NDC", payload: { group_index: group_index, private_price_ndc: !private_price_ndc } });
    };
    const handlePublicPriceNDC = (group_index, public_price_ndc) => {
        dispatch({ type: "FLIGHT_SET_PUBLIC_PRICE_NDC", payload: { group_index: group_index, public_price_ndc: !public_price_ndc } });
    };

    // const swapJourneys = (old_index, new_index, group_index) => {
    //     dispatch(SwapMultidestJourney(group_index, old_index, new_index));
    // };

    const handleNewGroup = () => {
        // let next_index = flight_groups.length;
        let new_group = Object.assign({}, flight_groups[flight_groups.length - 1]);
        let temp_manual_request = manualRequest.slice();
        let temp_open_date_pickers = open_date_pickers.slice();

        new_group.travelers = [];
        dispatch(AddFlightGroup(new_group));

        temp_manual_request.push("");
        setManualRequest(temp_manual_request);

        temp_open_date_pickers.push(temp_open_date_pickers[flight_groups.length - 1]);
        dispatch(SetOpenDatePickers(temp_open_date_pickers));

        // getProviders(next_index);
    };

    const deleteGroup = (group_index) => {
        let temp_open_date_pickers = open_date_pickers.slice();
        let temp_manual_request = manualRequest.slice();

        temp_open_date_pickers.splice(group_index, 1);
        temp_manual_request.splice(group_index, 1);

        setManualRequest(temp_manual_request);
        dispatch(SetOpenDatePickers(temp_open_date_pickers));
        dispatch(DeleteFlightGroupsAtIndex(group_index));
    };

    const handleManual = (value, group_index) => {
        if (group_index < manualRequest.length) {
            let temp_manual_request = manualRequest.slice();
            temp_manual_request[group_index] = value;
            setManualRequest(temp_manual_request);
        }
    };

    const checkCart = () => {
        let is_in_cart = false;
        let group = flight_groups[index_group !== null ? index_group : 0];
        let group_travelers_ids = [];
        group.travelers.map((traveler) => group_travelers_ids.push(traveler.id));
        group_travelers_ids.sort((a, b) => a - b);

        group.journey.map((journey) => {
            flight_cart.map((cart) => {
                let cart_travelers = cart.group_passenger?.travelers.sort((a, b) => a - b) ?? [];
                if (cart.is_displayed && cart_travelers.every((item, index) => item === group_travelers_ids[index])) {
                    if (moment.utc(journey.start_date).format("DD/MM/YYYY") === moment.utc(cart.start_date).format("DD/MM/YYYY") || moment.utc(journey.start_date).format("DD/MM/YYYY") === moment.utc(cart.end_date).format("DD/MM/YYYY")) {
                        is_in_cart = true;
                    }
                    setCartDate(journey.start_date);
                }
            }
            );
        });
        if (is_in_cart) {
            setAlreadyInCart(is_in_cart);
        } else {
            //continue search
            launchSearch();
        }
    };

    const selectProviders = (list_providers, group_index) => {
        dispatch(SelectProvider(list_providers, group_index));
    };

    const renderProviders = (list_providers) => {
        let render_providers = [];
        list_providers.map((provider_id) => {
            let full_provider = flight_providers.find(item => item.id === provider_id);
            if (full_provider !== undefined) {
                render_providers.push(full_provider.name);
            }
        });
        return render_providers.join(",");
    };

    const launchSearch = () => {
        let error = CheckGroups(flight_groups, end_date, manualRequest, enqueueSnackbar, t, dispatch);
        if (error === false) {
            dispatch(SetState());
            dispatch(SetFetchedDataStatus(null));
            dispatch(SetFetchedMatrixStatus(null));
            dispatch(ResetProvider());
            dispatch(SaveFlightGroups());
            if (origin_url === "groups") {
                dispatch(SetGroupIndex(0));
                dispatch(SetGroupCopyIndex(0));
                dispatch(InitStopOverActive(flight_groups[0].journey.length));
            } else {
                dispatch(InitStopOverActive(flight_groups[index_group].journey.length));
            }

            let is_manual = false;
            manualRequest.map((manual) => {
                if (manual !== "") {
                    is_manual = true;
                }
            });
            if ((flight_groups[0].journey_type !== 4 && flight_groups[0].journey_type !== 5) || !is_manual) {
                if (origin_url === "search") {
                    setLoadingRequest(false);
                    setSearchGroup(flight_groups[index_group]);
                    if (source !== null) {
                        source.cancel();
                    }
                    source = axios.CancelToken.source();
                    if (flight_groups[index_group].flexible_date) {
                        dispatch(SetShowMoreResults(false));
                        GetMinimalPrices(flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, use_cache, "matrix", dispatch, source);
                    } else {
                        GetMinimalPrices(flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, use_cache, "set_flights", dispatch, source);
                    }
                    setDisplay(false);
                } else {
                    router.push(`/${window.url_name}/apps/flight-search/true`);
                }
            } else {
                setManualSearching(true);
            }
        }
    };
    // const renderSegment = useCallback((journey_step, journey_index, group_index, journey_type) => {
    //     return <SearchFlightBar display={display} key={`${group_index}-${journey_index}`} group_index={group_index} journey_type={journey_type} journey_index={journey_index} journey_step={journey_step} />;
    // }, []);
    return (
        <Container maxWidth={"lg"} disableGutters={isHandHeld ? true : false} style={display ? { display: "block", marginBottom: isHandHeld ? 65 : 0 } : { display: "none" }}>
            {
                origin_url === "groups" && (
                    <Grid container spacing={3}>
                        {/* <Grid item className={classes.genericIcon}> <FlightIcon /> </Grid> */}
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <Typography align={'center'} variant="h5" className={classes.genericText}> {t("global.flights")} </Typography>
                        </Grid>
                    </Grid>

                )
            }
            <Grid item xs={12} className={isHandHeld ? "" : classes.spacer}>
                {
                    flight_groups.map((group, group_index) => {
                        if (index_group === null || group_index >= index_group) {
                            return (
                                <Grid key={group_index} className={classes.verticalSpacer}>
                                    {
                                        !isHandHeld && flight_groups.length !== 1 && (
                                            <Grid className={`${classes.genericText} ${classes.fontSize24} ${classes.bold}`}>
                                                {t("flight_groups.group") + (group_index + 1)}
                                            </Grid>
                                        )
                                    }
                                    {
                                        isHandHeld && flight_groups.length !== 1 && (
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item className={`${classes.genericText} ${classes.fontSize24} ${classes.bold}`}>
                                                    {t("flight_groups.group") + (group_index + 1)}
                                                </Grid>
                                                {
                                                    group_index !== 0 && (
                                                        <Grid item>
                                                            <IconButton size="small" onClick={() => deleteGroup(group_index)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        )
                                    }
                                    <Paper elevation={0} variant={isHandHeld ? "elevation" : "outlined"} className={clsx(classes.container, { [classes.formShadow]: isHandHeld })}>
                                        <Grid className={isHandHeld ? "" : classes.genericPadding}>
                                            <Grid container alignItems="center" justify={isHandHeld ? "center" : "space-between"}>
                                                <Grid item>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup row={isHandHeld ? false : true} name="search-type" value={group.journey_type} onChange={(event) => {
                                                            handleJourneyType(event.target.value, group_index);
                                                        }}>
                                                            <FormControlLabel value={1} control={<Radio sx={{
                                                                '&.Mui-checked': {
                                                                    color: `${main_color}`
                                                                }
                                                            }} className={classes.radio} />} label={t("global.round_trip")} labelPlacement="end" classes={{ label: `${classes.genericText} ${classes.fontSize10}` }} />
                                                            <FormControlLabel value={3} control={<Radio sx={{
                                                                '&.Mui-checked': {
                                                                    color: `${main_color}`
                                                                }
                                                            }} className={classes.radio} />} label={t("global.one_way")} labelPlacement="end" classes={{ label: `${classes.genericText} ${classes.fontSize10}` }} />
                                                            <FormControlLabel value={2} control={<Radio sx={{
                                                                '&.Mui-checked': {
                                                                    color: `${main_color}`
                                                                }
                                                            }} className={classes.radio} />} label={t("global.multi_destination")} labelPlacement="end" classes={{ label: `${classes.genericText} ${classes.fontSize10}` }} />
                                                            {
                                                                flight_providers.find(provider => ["1G", "eva_amadeus"].includes(provider.code)) !== undefined &&
                                                                <FormControlLabel value={4} control={<Radio sx={{
                                                                    '&.Mui-checked': {
                                                                        color: `${main_color}`
                                                                    }
                                                                }} className={classes.radio} />} label={t("global.manual_destination")} labelPlacement="end" classes={{ label: `${classes.genericText} ${classes.fontSize10}` }} />
                                                            }
                                                            {
                                                                (quotation_code !== "visiteurs" || user.client_full.type !== 2) && flight_providers.find(provider => ["amadeus_rest", "1G", "amadeus_soap", "sabre_rest"].includes(provider.code)) !== undefined &&
                                                                <FormControlLabel value={5} control={<Radio sx={{
                                                                    '&.Mui-checked': {
                                                                        color: `${main_color}`
                                                                    }
                                                                }} className={classes.radio} />} label={t("global.retrieve_pnr")} labelPlacement="end" classes={{ label: `${classes.genericText} ${classes.fontSize10}` }} />
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    !isHandHeld && group_index !== 0 && (
                                                        <Grid item>
                                                            <IconButton size="small" onClick={() => deleteGroup(group_index)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            {
                                                group_index < open_date_pickers.length && open_date_pickers[group_index].length !== 0 && group.journey_type !== 4 && group.journey_type !== 5 && (

                                                    //<SortableGrid lockAxis={ "y" } useDragHandle={true} onSortEnd={(data) => swapJourneys(data.oldIndex, data.newIndex, group_index) }>
                                                    //{
                                                    //group.journey.map((journey_step, journey_index) => {
                                                    //if (group.journey_type === 2 && journey_index !== 0){
                                                    //let SortableBar = SortableElement(SearchFlightBar);
                                                    //return(
                                                    //<SortableBar display={display} key={`${group_index}-${journey_index}`} index={journey_index} group_index={group_index} journey_type={group.journey_type} journey_index={journey_index} journey_step={journey_step} />
                                                    //)
                                                    //} else{
                                                    //return(
                                                    //<SearchFlightBar display={display} key={`${group_index}-${journey_index}`} group_index={group_index} journey_type={group.journey_type} journey_index={journey_index} journey_step={journey_step} />
                                                    //)
                                                    //}
                                                    //})
                                                    //}
                                                    //</SortableGrid>
                                                    <DndProvider backend={HTML5Backend}>
                                                        {
                                                            //group.journey_type === 2 &&  group.journey.map((journey_step, journey_index) => {renderSegment(journey_step, journey_index, group_index, group.journey_type)})
                                                        }
                                                        {
                                                            group.journey.map((journey_step, journey_index) => {
                                                                return <SearchFlightBar display={display} key={`${group_index}-${journey_index}`} group_index={group_index} journey_type={group.journey_type} journey_index={journey_index} journey_step={journey_step} />;
                                                            })
                                                        }
                                                    </DndProvider>

                                                )
                                            }
                                            {
                                                group.journey_type === 4 && quotation_code === "visiteurs" && (
                                                    <Alert severity={"warning"} variant={"filled"} style={{ margin: "8px 0px" }}>
                                                        {t("flight.class_warning")}
                                                    </Alert>
                                                )
                                            }
                                            {
                                                group.journey_type === 4 && (
                                                    <Grid container>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={10}>
                                                            <FormControl variant="outlined" className={classes.formControlRoot}>
                                                                <InputLabel id={"info-label"} ref={manualReqLabel}>
                                                                    {t("flight_groups.cryptic_request")}
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    name={"info-label"}
                                                                    id={"info-input"}
                                                                    labelWidth={manualReqLabelWidth}
                                                                    className={classes.circleBorder}
                                                                    value={manualRequest[group_index]}
                                                                    onChange={(e) => handleManual(e.target.value, group_index)}
                                                                    inputComponent={"textarea"}
                                                                    classes={{ input: classes.heightManualReq }}
                                                                    style={{ paddingTop: 4 }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                group.journey_type === 5 && (
                                                    <Grid container>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={10}>
                                                            <FormControl variant="outlined" className={classes.formControlRoot}>
                                                                <InputLabel id={"info-label"} ref={manualReqLabel}>
                                                                    {t("global.retrieve_pnr")}
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    name={"info-label"}
                                                                    id={"info-input"}
                                                                    labelWidth={manualReqLabelWidth}
                                                                    className={classes.circleBorder}
                                                                    value={manualRequest[group_index]}
                                                                    onChange={(e) => handleManual(e.target.value, group_index)}
                                                                    inputComponent={"textarea"}
                                                                    classes={{ input: classes.heightManualReq }}
                                                                    style={{ paddingTop: 4 }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                group.journey_type === 2 && (
                                                    <Grid container>
                                                        {!isHandHeld && <Grid item xs={9} />}
                                                        <Grid item xs={isHandHeld ? 12 : 2} className={isHandHeld ? classes.textCenter : classes.textRight}>
                                                            <Button size={isHandHeld ? "medium" : "small"} className={`${classes.genericText} ${isHandHeld ? classes.fontSize16 : classes.fontSize10} ${isHandHeld ? "" : classes.underline} ${classes.bold}`} onClick={() => addFlightMultiDest(group_index)}>
                                                                {t("flight_groups.add_journey")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                group.journey_type !== 5 &&
                                                <Grid container direction={isHandHeld ? "column" : "row"} spacing={isHandHeld ? 2 : 0} className={!isHandHeld ? classes.verticalSpacer : ""} style={isHandHeld ? { padding: "0px 0px 12px" } : {}}>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={isHandHeld ? 12 : 3} style={{ backgroundColor: 'white' }}>
                                                        <ChoosePassenger origin_url={origin_url} group_index={group_index} group_travelers={group.travelers} />
                                                    </Grid>
                                                    {
                                                        flight_param.length === 0 &&
                                                        <Grid item xs={isHandHeld ? 12 : 5} style={{ backgroundColor: 'white' }}>
                                                            {
                                                                group.journey_type !== 4 && (
                                                                    <AirlineSearch group_index={group_index} group_airlines={group.company} />
                                                                )
                                                            }
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {
                                                !replaceProductContext.enableReplace && group.journey_type !== 5 &&
                                                <SearchHistory group_index={group_index} />
                                            }
                                        </Grid>
                                        {
                                            group.journey_type !== 5 &&
                                            <Grid className={isHandHeld ? "" : classes.genericPadding}>
                                                <Grid container alignItems="center" justify="flex-start" spacing={1}>
                                                    {/* <Grid item xs={1} /> */}
                                                    <Grid item>
                                                        {
                                                            group.journey_type !== 4 && flight_param.length === 0 && (
                                                                <FormGroup row>
                                                                    <FormControlLabel
                                                                        control={<Checkbox sx={{
                                                                            '&.Mui-checked': {
                                                                                color: `${main_color}`
                                                                            }
                                                                        }} className={classes.checkbox} checked={group.direct_flight} onChange={() => handleDirectFlight(group_index, group.direct_flight)} name="direct-flight" />}
                                                                        label={t("flight_groups.direct_flight")}
                                                                        classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox sx={{
                                                                            '&.Mui-checked': {
                                                                                color: `${main_color}`
                                                                            }
                                                                        }} className={classes.checkbox} checked={group.one_stop} onChange={() => handleOneStop(group_index, group.one_stop)} name="one-stop" />}
                                                                        label={t("flight_groups.with_stop")}
                                                                        classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                    />
                                                                    {
                                                                        group.provider !== null && (flight_providers.find(item => item.code === "1G") === undefined || group.provider.includes(flight_providers.find(item => item.code === "1G").id)) && (
                                                                            <FormControlLabel
                                                                                control={<Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} className={classes.checkbox} checked={group.included_luggage} onChange={() => handleIncludedLuggage(group_index, group.included_luggage)} name="included-luggage" />}
                                                                                label={t("flight_groups.included_luggage")}
                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                            />
                                                                        )
                                                                    }
                                                                    {
                                                                        group.journey.length <= 2 && quotation_code !== "visiteurs" && (
                                                                            <FormControlLabel
                                                                                control={<Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} className={classes.checkbox} checked={group.flexible_date} onChange={() => handleFlexibleDate(group_index, group.flexible_date)} name="flexible-date" />}
                                                                                label={t("flight_groups.flexible_date")}
                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                            />
                                                                        )
                                                                    }

                                                                </FormGroup>
                                                            )
                                                        }
                                                        {
                                                            quotation_code !== "visiteurs" && group.provider !== null && (flight_providers.find(item => item.code === "1G") === undefined || group.provider.includes(flight_providers.find(item => item.code === "1G").id)) && group.journey_type === 4 && (
                                                                <FormControlLabel
                                                                    control={<Checkbox sx={{
                                                                        '&.Mui-checked': {
                                                                            color: `${main_color}`
                                                                        }
                                                                    }} className={classes.checkbox} checked={group.manual_luggage} onChange={() => dispatch(SetManualLuggage(group_index, !group.manual_luggage))} name="included-luggage" />}
                                                                    label={t("flight_groups.included_luggage")}
                                                                    classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            ['visiteurs'].includes(quotation_code) && user.client_full.type === 2 &&
                                                            <FormGroup row>
                                                                <FormControlLabel
                                                                    control={<Checkbox sx={{
                                                                        '&.Mui-checked': {
                                                                            color: `${main_color}`
                                                                        }
                                                                    }} className={classes.checkbox} checked={group.public_price} onChange={() => handlePublicPrice(group_index, group.public_price)} name="public-price" />}
                                                                    label={t("flight_groups.public_price")}
                                                                    classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                />
                                                            </FormGroup>
                                                        }
                                                        {
                                                            ['visiteurs'].includes(quotation_code) && user.client_full.type !== 2 &&
                                                            <FormGroup row>
                                                                <FormControlLabel
                                                                    control={<Checkbox sx={{
                                                                        '&.Mui-checked': {
                                                                            color: `${main_color}`
                                                                        }
                                                                    }} className={classes.checkbox} checked={group.public_price} onChange={() => handlePublicPrice(group_index, group.public_price)} name="public-price" />}
                                                                    label={t("flight_groups.public_price")}
                                                                    classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox sx={{
                                                                        '&.Mui-checked': {
                                                                            color: `${main_color}`
                                                                        }
                                                                    }} checked={group.private_price} onChange={() => handlePrivatePrice(group_index, group.private_price)} name="private-price" />}
                                                                    label={t("flight_groups.negociated_price")}
                                                                    classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                />
                                                            </FormGroup>
                                                        }
                                                        {
                                                            !['tripadekua', 'americavoyages', 'visiteurs'].includes(quotation_code) && user.client_full.type !== 2 &&
                                                            <>
                                                                {
                                                                    flight_param.length === 0 &&
                                                                    <FormGroup row>
                                                                        <div style={{ marginRight: group.standard_price ? 28 : 62 }}>
                                                                            <FormControlLabel
                                                                                control={<Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} className={classes.checkbox} checked={group.standard_price} onChange={() => handleStandardPrice(group_index, group.standard_price)} name="standard-price" />}
                                                                                label={t("flight_groups.standard")}
                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                            />
                                                                            {
                                                                                flight_param.length === 0 && group.standard_price &&
                                                                                <Box row sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox sx={{
                                                                                            '&.Mui-checked': {
                                                                                                color: `${main_color}`
                                                                                            }
                                                                                        }} className={classes.checkbox} checked={group.public_price} onChange={() => handlePublicPrice(group_index, group.public_price)} name="public-price" />}
                                                                                        label={t("flight_groups.public_price")}
                                                                                        classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                                    />
                                                                                    {
                                                                                        (quotation_code !== "visiteurs" || user.client_full.type !== 2) && (
                                                                                            <FormControlLabel
                                                                                                control={<Checkbox sx={{
                                                                                                    '&.Mui-checked': {
                                                                                                        color: `${main_color}`
                                                                                                    }
                                                                                                }} checked={group.private_price} onChange={() => handlePrivatePrice(group_index, group.private_price)} name="private-price" />}
                                                                                                label={t("flight_groups.negociated_price")}
                                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </Box>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <FormControlLabel
                                                                                control={<Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} className={classes.checkbox} checked={group.ndc_price} onChange={() => handleNDCPrice(group_index, group.ndc_price)} name="ndc-price" />}
                                                                                label={t("flight_groups.ndc")}
                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                            />
                                                                            {
                                                                                flight_param.length === 0 && group.ndc_price &&
                                                                                <Box row sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox sx={{
                                                                                            '&.Mui-checked': {
                                                                                                color: `${main_color}`
                                                                                            }
                                                                                        }} className={classes.checkbox} checked={group.public_price_ndc} onChange={() => handlePublicPriceNDC(group_index, group.public_price_ndc)} name="public-price-ndc" />}
                                                                                        label={t("flight_groups.public_price")}
                                                                                        classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                                    />
                                                                                    {
                                                                                        (quotation_code !== "visiteurs" || user.client_full.type !== 2) && (
                                                                                            <FormControlLabel
                                                                                                control={<Checkbox sx={{
                                                                                                    '&.Mui-checked': {
                                                                                                        color: `${main_color}`
                                                                                                    }
                                                                                                }} className={classes.checkbox} checked={group.private_price_ndc} onChange={() => handlePrivatePriceNDC(group_index, group.private_price_ndc)} name="private-price-ndc" />}
                                                                                                label={t("flight_groups.negociated_price")}
                                                                                                classes={{ label: `${classes.genericText} ${classes.fontSize10} ${classes.bold}` }}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </Box>
                                                                            }
                                                                        </div>
                                                                    </FormGroup>
                                                                }
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            // (group.journey_type === 5 && user.client_full.type !== 2) && group.provider !== null && (
                                            (["localhost:3000", "demo.facilitatrip.com", "dev.facilitatrip.com"].includes(window.location.host) || (group.journey_type === 5 && user.client_full.type !== 2)) && group.provider !== null && (
                                                <Grid container justify="center" className={isHandHeld ? "" : classes.genericPadding}>
                                                    <Grid item xs={isHandHeld ? 12 : 6} style={{ backgroundColor: 'white' }}>
                                                        <FormControl variant="outlined" className={classes.formControlRoot}>
                                                            <InputLabel id={"provider-label"} ref={selectProviderLabel}>
                                                                {t("flight_groups.select_provider")}
                                                            </InputLabel>
                                                            <Select
                                                                labelId={"provider-label"}
                                                                value={group.provider}
                                                                multiple={true}
                                                                renderValue={(value) => renderProviders(value)}
                                                                onChange={(e) => selectProviders(e.target.value, group_index)}
                                                                input={
                                                                    <OutlinedInput
                                                                        labelWidth={selectProviderLabelWidth}
                                                                        autoComplete="off"
                                                                        label={t("flight_groups.select_provider")}
                                                                        className={`${classes.genericText} ${classes.fontSize10} ${classes.uppercase}`}
                                                                    />
                                                                }
                                                            >
                                                                {
                                                                    flight_providers.map((flight_provider) => {
                                                                        return (
                                                                            <MenuItem value={flight_provider.id} key={flight_provider.id}>
                                                                                <Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} checked={group.provider.includes(flight_provider.id)} />
                                                                                {flight_provider.name}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Paper>
                                </Grid>
                            );
                        }
                    })
                }
                <Grid className={isHandHeld ? classes.textCenter : classes.textRight}>
                    <Button size="small" className={`${classes.genericText} ${isHandHeld ? classes.fontSize16 : classes.fontSize10} ${isHandHeld ? "" : classes.underline} ${classes.bold}`} onClick={() => handleNewGroup()}>
                        {t("flight_groups.add_passenger_group")}
                    </Button>
                </Grid>
                <Grid container alignItems="center" justify={isTablet ? "center" : "flex-end"} spacing={2} direction={isMobile ? "column" : "row"} className={classes.verticalSpacer}>
                    {
                        origin_url !== "groups" && (
                            <Grid item xs={isMobile ? 12 : false}>
                                <Button size="small" variant="contained" fullWidth={isMobile} className={classes.cancelButton} onClick={() => setDisplay(!display)} style={isMobile ? {} : { marginRight: 30 }}>
                                    <div className={classes.cancelButtonText}>
                                        {t("global.cancel")}
                                    </div>
                                </Button>
                            </Grid>
                        )
                    }
                    <Grid item xs={isMobile ? 12 : false}>
                        <Button size="small" variant="contained" fullWidth={isMobile} className={manualSearching ? classes.searchButtonProgress : classes.searchButton} disabled={manualSearching} onClick={() => checkCart()}>
                            {t("global.search")}
                            {manualSearching && <CircularProgress size={20} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <AlreadyInCart alreadyInCart={alreadyInCart} setAlreadyInCart={setAlreadyInCart} journey_start_date={cartDate} setCancelSearch={setCancelSearch} />
            <AcceptPublicPrice agencyPublicPrice={agencyPublicPrice} setAgencyPublicPrice={setAgencyPublicPrice} group_index={agencyPublicPriceGroup} />
        </Container>
    );
});

FlightGroups.defaultProps = {
    origin_url: "groups",
    display: true,
    setDisplay: null,
    setSearchGroup: null,
    use_cache: true
};

export default React.memo(FlightGroups);
