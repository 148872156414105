import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import { makeStyles, useTheme } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import CheckDefaultNames from '../Menu/MaterialTripList/Functions/CheckDefaultNames';
import CheckTravelerIdentical from '../Menu/MaterialTripList/Functions/CheckTravelerIdentical';
import CheckNamesCharacters from '../Menu/MaterialTripList/Functions/CheckNamesCharacters';
import CheckLeadContact from './Functions/CheckLeadContact';
import CheckDriver from './Functions/CheckDriver';
import CheckPaxWeight from './Functions/CheckPaxWeight';
import CheckTransferInfo from './Functions/CheckTransferInfo';
import SendDemandProductsToBook from './Functions/SendDemandProductsToBook';

import { useSendProviderBookingDemand } from "../BookingMaterial/network/sendProviderBookingDemand";
import { useMarkBookingForManualProducts } from "../BookingMaterial/network/markBookingForManualProducts";
import { useMarkBookingForOfflineProducts } from "../BookingMaterial/network/markBookingForOfflineProducts";
import { useShowError } from "../Utils/showError";
import { useGetFlightSellingPrice } from "../CartMaterial/utils/getFlightSellingPrice";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from '../Common/Functions/GetCookie';

import { SetIsBooking, SetChecksPassed, SetSameTravelerID } from '../../Actions/Booking';
import { EditTraveler, SetDataTrip } from "../../Actions/Trip";

import axios from 'axios';
import moment from "moment";
import { groupBy, isEmpty, isNumber, uniq } from 'lodash';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const useStyles = makeStyles({
    stickyPaper: {
        position: 'sticky',
        top: 16,
        borderRadius: 16,
        padding: 16
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white',
        marginTop: 16
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    strong: {
        fontWeight: "bold"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize18: {
        fontSize: 18
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textRight: {
        textAlign: "right"
    }
});

const BookingCard = withRouter(({ router, tempTravelers, infoIncomplete, setInfoIncomplete }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const toBeBookedPackages = useSelector((state) => state.cartConstruction.bookablePackages);
    const to_book = useSelector(store => store.cart.to_book);
    const currency_list = useSelector(state => state.base.currency_list);
    const currency = useSelector((state) => state.trip.currency);
    const trip_id = useSelector(store => store.trip.trip_id);
    const trip = useSelector((state) => state.trip.data_trip);
    const trip_reference = useSelector(store => store.trip.trip_reference);
    const current_version = useSelector(store => store.trip.data_trip);
    const toBeBookedProducts = useSelector((state) => state.cart.to_book);
    const providers = useSelector(store => store.trip.providers);
    const user = useSelector(state => state.user.user);
    const end_date = useSelector(state => state.trip.end_date);
    const bookablePackages = useSelector((state) => state.cartConstruction.bookablePackages);
    const bookableManualProducts = useSelector((state) => state.cartConstruction.bookableManualProducts);
    const showError = useShowError();
    const sendBookingDemand = useSendProviderBookingDemand({
        onError(error) {
            showError(error);
        }
    });
    const markBookingForManualProducts = useMarkBookingForManualProducts();
    const markBookingForOfflineProducts = useMarkBookingForOfflineProducts();
    const [getFlightSellingPrice] = useGetFlightSellingPrice();

    const [versionNb, setVersionNb] = useState(null);
    const [spinRefresh, setSpinRefresh] = useState(false);
    const [toBookPrice, setToBookPrice] = useState([]);
    const [checkConfirmTravelers, setCheckConfirmTravelers] = useState(null);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    useEffect(() => {
        let temp_version_nb = 1;
        let { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/?token=${GetCookie("trip_token")}`,
                headers: headers
            }).then(function (response) {
                response.data.results.sort((a, b) => a.id - b.id).map((version, version_index) => {
                    if (version.id === current_version.id) {
                        temp_version_nb = version_index + 1;
                    }
                });
                setVersionNb(temp_version_nb);
            });
        }
    }, [current_version]);

    useEffect(() => {
        let total_cost = [];
        to_book.map((product) => {
            if (
                (
                    !isProductPackaged({
                        product,
                        stackInfos: trip?.stack_info ?? null
                    }) ||
                    isProductPackaged({
                        product,
                        stackInfos: trip?.stack_info ?? null,
                        for_price: true
                    })
                ) &&
                (
                    user.client_full.type !== 2 ||
                    !product.deletion_restriction
                )
            ) {
                let price = getPrice(product.prices);
                let matched = false;
                if (!product.is_manual && product.product_type === 6) {
                    price = getFlightSellingPrice(product)[0];
                } else if (product.is_manual && product.product_type === 6) {
                    price.cost += parseFloat(product.taxes);
                }
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    let new_cost = price.cost;
                    total_cost.push({
                        cost: new_cost,
                        currency: price.currency
                    });
                }
            }
        });

        if (
            (
                toBeBookedPackages?.findIndex((packageItem) => {
                    return isProductPackaged({
                        product: packageItem,
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    });
                }) ?? -1
            ) >= 0
        ) {
            const packageFlightPrices = trip?.prices_flight?.filter((price) => {
                return !isEmpty(price) &&
                    isProductPackaged({
                        product: price,
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    });
            }) ?? [];
            const packageTerrestrialPrices = trip?.prices_terrestrial?.filter((price) => {
                return !isEmpty(price) &&
                    isProductPackaged({
                        product: price,
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    });
            }) ?? [];
            if (packageFlightPrices.length > 0) {
                const grouppedPrices = groupBy(
                    packageFlightPrices,
                    (item) => `${item.stack_number}-${item.stack_info_id}`
                );
                const pricesGroup = Object.values(grouppedPrices);
                for (const item of pricesGroup) {
                    let price = getPrice(item);
                    let matched = false;
                    total_cost.map((currency_cost) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0);
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0),
                            currency: price.currency
                        });
                    }
                }
            }
            if (packageTerrestrialPrices.length > 0) {
                const grouppedPrices = groupBy(
                    packageTerrestrialPrices,
                    (item) => `${item.stack_number}-${item.stack_info_id}`
                );
                const pricesGroup = Object.values(grouppedPrices);
                for (const item of pricesGroup) {
                    let price = getPrice(item);
                    let matched = false;
                    total_cost.map((currency_cost) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        }
        total_cost.map(currency_cost => currency_cost.cost = currency_cost.cost.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setToBookPrice(total_cost);
    }, [trip, to_book, toBeBookedPackages]);

    const priceToDisplay = price => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };
    const getPrice = prices => {
        prices = prices ?? [];
        if (user.client_full.type === 1 || user.client_full.type === 3 || JSON.parse(localStorage.getItem('config')).quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };

    const confirmTempTravelers = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let temp_info_incomplete = infoIncomplete.slice();

        let default_names_check = CheckDefaultNames(tempTravelers);
        if (!default_names_check) {
            enqueueSnackbar(t("booking.traveler_default_name"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }

        let identical_traveler_check = CheckTravelerIdentical(tempTravelers);
        if (!identical_traveler_check.check_passed) {
            enqueueSnackbar(t("booking.identical_traveler"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }
        dispatch(SetSameTravelerID(identical_traveler_check.traveler_identical));

        let characters_check = CheckNamesCharacters(tempTravelers);
        if (!characters_check) {
            enqueueSnackbar(t("booking.traveler_special_char_err"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }

        if (default_names_check && identical_traveler_check.check_passed && pass_check) {
            let nb_valid_travelers = 0;
            tempTravelers.map((traveler, traveler_index) => {
                temp_info_incomplete[traveler_index] = false;
                if (traveler.identity_doc_number !== "" && traveler.identity_doc_number !== null) {
                    if (traveler.identity_doc_country_emission === null || traveler.identity_doc_country_emission === "" || traveler.identity_doc_expiration === "" || traveler.identity_doc_expiration === null || traveler.nationality === null || traveler.nationality === "") {
                        enqueueSnackbar(t("booking.passport_issues"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 8000 });
                        temp_info_incomplete[traveler_index] = true;
                    }
                }
                if (temp_info_incomplete[traveler_index] === false) {
                    let request = {
                        address: traveler.address,
                        address_details: traveler.address_details,
                        birth_date: traveler.birth_date,
                        city: traveler.city,
                        country: traveler.country !== null ? traveler.country.id : null,
                        email: traveler.email !== "" ? traveler.email : null,
                        first_name: traveler.first_name !== "" ? traveler.first_name : t("global.first_name"),
                        gender: traveler.gender,
                        identity_doc: traveler.identity_doc,
                        identity_doc_country_emission: traveler.identity_doc_country_emission !== null ? traveler.identity_doc_country_emission.id : null,
                        identity_doc_expiration: traveler.identity_doc_expiration !== "" ? traveler.identity_doc_expiration : null,
                        identity_doc_number: traveler.identity_doc_number !== "" ? traveler.identity_doc_number : null,
                        last_name: traveler.last_name !== "" ? traveler.last_name : t("global.last_name"),
                        loyalty_cards: traveler.loyalty_cards,
                        middle_name: traveler.middle_name,
                        nationality: traveler.nationality !== null ? traveler.nationality.id : null,
                        phone_country_code: traveler.phone_country_code,
                        phone_number: traveler.phone_number,
                        postal_code: traveler.postal_code,
                        title: traveler.title,
                        traveler_relationship: traveler.traveler_relationship,
                        trip_reason: traveler.trip_reason
                    };
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/travelers/${traveler.id}/?token=${GetCookie("trip_token")}`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        nb_valid_travelers += 1;
                        setCheckConfirmTravelers(nb_valid_travelers);
                        enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        dispatch(EditTraveler(response.data, traveler.real_index));
                    }).catch(function (error) {
                        setCheckConfirmTravelers(false);
                        if (error.response?.status === 409 && error.response.data.detail !== undefined) {
                            enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });
                        } else {
                            enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        }
                    });
                }
            });
            setInfoIncomplete(temp_info_incomplete);
        } else {
            setCheckConfirmTravelers(false);
        }
    };

    useEffect(() => {
        if (checkConfirmTravelers !== null && checkConfirmTravelers !== false) {
            if (checkConfirmTravelers === tempTravelers.length) {
                if (
                    user.client_full.type !== 2 ||
                    quotationCode === 'beachcomber'
                ) {
                    launchBooking();
                } else {
                    sendDemandBooking();
                }
            } else {
                dispatch(SetChecksPassed(false));
            }
        }
    }, [checkConfirmTravelers]);

    const launchProcess = () => {
        let bookable_products = false;
        to_book.map((product) => {
            if (product.is_available && product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                bookable_products = true;
            }
        });
        if (bookable_products === false && bookableManualProducts.length === 0) {
            enqueueSnackbar(t("booking.empty_to_book"), { variant: "error", disableWindowBlurListener: true });
        } else {
            if (bookable_products) {
                confirmTempTravelers();
            }
            if (
                user.client_full.type !== 2 ||
                quotationCode === 'beachcomber'
            ) {
                (async () => {
                    const version = parseInt(GetCookie('trip_id_version') ?? '-1');
                    const sentProvidersIds = [];
                    const stackNumbers = (
                        await Promise.all(
                            bookablePackages.filter((item) => {
                                return !isProductPackaged({
                                    product: item,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                });
                            }).map(async (packageItem) => {
                                const providerId = trip?.prices_flight?.find((item) => {
                                    return item.stack_number === packageItem.stack_number &&
                                        item.stack_info_id === packageItem.stack_info_id;
                                })?.provider ?? trip?.prices_terrestrial?.find((item) => {
                                    return item.stack_number === packageItem.stack_number &&
                                        item.stack_info_id === packageItem.stack_info_id;
                                })?.provider;
                                if (isNumber(providerId)) {
                                    const sent = await sendBookingDemand(version, providerId);

                                    if (sent) {
                                        sentProvidersIds.push(providerId);
                                    }

                                    return sent ? packageItem : null;
                                }
                            })
                        )
                    ).filter((stackNumber) => {
                        return isNumber(stackNumber);
                    });
                    for (const stackNumber of stackNumbers) {
                        enqueueSnackbar(
                            t('cart-material.cart-construction-package-booking-sent', { name: stackNumber }),
                            { variant: 'success' }
                        );
                    }

                    const offlineProducts = to_book.filter((item) => {
                        return item.is_custom;
                    });

                    if (offlineProducts.length > 0) {
                        await markBookingForOfflineProducts(offlineProducts)
                    }

                    if (
                        bookableManualProducts.length > 0 &&
                        await markBookingForManualProducts()
                    ) {
                        const offlineProductsProviderIds = uniq(
                            offlineProducts.map((product) => {
                                return product.provider;
                            })
                        );
                        const providerIds = uniq(
                            bookableManualProducts.map((product) => {
                                return product.provider;
                            })
                        ).filter((providerId) => {
                            return !sentProvidersIds.includes(providerId) &&
                                   !offlineProductsProviderIds.includes(providerId);
                        });

                        const allSent = await Promise.all(
                            providerIds.map(async (providerId) => {
                                return sendBookingDemand(version, providerId);
                            })
                        );

                        if (allSent.every((item) => item)) {
                            enqueueSnackbar(
                                t('cart-material.cart-construction-manual-products-booking-sent'),
                                { variant: 'success' }
                            );
                        } else {
                            enqueueSnackbar(
                                t('cart-material.cart-construction-manual-products-booking-error'),
                                { variant: 'error' }
                            );
                        }
                    } else if (bookableManualProducts.length > 0) {
                        enqueueSnackbar(
                            t('cart-material.cart-construction-manual-products-booking-error'),
                            { variant: 'error' }
                        );
                    }
                })();
            }
        }
    };

    const launchBooking = () => {
        let lead_check = CheckLeadContact(to_book);
        let driver_check = CheckDriver(to_book);
        let weight_check = CheckPaxWeight(to_book);
        let transfer_check = CheckTransferInfo(to_book, providers);
        if (lead_check && driver_check && weight_check && transfer_check) {
            dispatch(SetChecksPassed(true));
            RefreshPrices(true);
        } else {
            dispatch(SetChecksPassed(false));
            enqueueSnackbar(t("booking.traveler_info_err"), { variant: "error", disableWindowBlurListener: true });
        }
    };

    const sendDemandBooking = () => {
        //change status for verdie
        if (['verdie', 'visiteurs', 'beachcomber'].includes(JSON.parse(localStorage.getItem('config')).quotation_code)) {
            let { pass_check, headers } = CheckBeforeRequest();
            let new_due_date = moment().add(3, "d").format();

            let request = {
                status: JSON.parse(localStorage.getItem('config')).quotation_code === 'verdie' ? "AGENCY_MOD" : "TOBOOK",
                due_date: new_due_date
            };
            if (pass_check) {
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${current_version.id}/?token=${GetCookie("trip_token")}`,
                    headers: headers,
                    data: JSON.stringify(request)
                }).then(function (response) {
                    enqueueSnackbar(t("menu.trip_list.success_change_status"), { variant: 'success', disableWindowBlurListener: true });
                    dispatch(SetDataTrip(response.data));
                    SendDemandProductsToBook(
                        to_book?.filter((item) => {
                            return !isProductPackaged({
                                product: item,
                                stackInfos: trip?.stack_info ?? null
                            });
                        }) ?? [],
                        dispatch,
                        end_date,
                        trip
                    );
                    enqueueSnackbar(t('cart-material.demand-to-book-sent'), { variant: 'success', disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error);
                    enqueueSnackbar(t("menu.trip_list.failed_change_status"), { variant: "error", disableWindowBlurListener: true });
                });
            }
        } else {
            enqueueSnackbar(t('cart-material.demand-to-book-sent'), { variant: 'success' });
            dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
            router.push(`/${window.url_name}/apps/cart`);
        }
    };

    const RefreshPrices = (booking) => {
        setSpinRefresh(true);
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/refresh_prices/`
            }).then(response => {
                console.log('response.data : ', response.data);
                dispatch({ type: 'CART_REFRESH_PRICE', payload: response.data });
                setSpinRefresh(false);
                if (booking) {
                    dispatch(SetIsBooking(true));
                } else {
                    dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
                }
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.refresh-price-not-available'), { variant: 'warning' });
            });
        }
    };

    return (
        <Card className={classes.stickyPaper} raised>
            {
                !downDesktop && (
                    <Grid container alignItems="center" justify="flex-start">
                        <Grid item xs={4} className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize18} ${classes.upperCase} ${classes.letterSpacing}`}>
                            {t('cart-material.folder')}
                        </Grid>
                        <Grid item xs={2} className={`${classes.strong} ${classes.fontSize18} ${classes.letterSpacing}`}>
                            {trip_reference}
                        </Grid>
                        <Grid item xs={3} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                            {" " + t("global.version") + " " + versionNb}
                        </Grid>
                        <Grid item xs={3} className={classes.textRight}>
                            {
                                spinRefresh && (
                                    <Grid style={{ padding: "12px 12px 12px 0px" }}>
                                        <CircularProgress size={17} />
                                    </Grid>
                                )
                            }
                            {
                                !spinRefresh && (
                                    <Tooltip title={t("cart.refresh_price_tooltip")}>
                                        <IconButton style={{ float: "right" }} onClick={() => {
                                            dispatch(SetIsBooking(false));
                                            RefreshPrices(false);
                                        }}>
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
            {
                downDesktop && (
                    <Grid container alignItems="center" justify="flex-start">
                        <Grid item xs={9}>
                            <Grid container alignItems="center" justify="flex-start">
                                <Grid item className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    {t('cart-material.folder')}
                                </Grid>
                                <Grid item className={`${classes.strong} ${classes.fontSize14} ${classes.letterSpacing}`}>
                                    {trip_reference}
                                </Grid>
                            </Grid>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                                {" " + t("global.version") + " " + versionNb}
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight}>
                            {
                                spinRefresh && (
                                    <Grid style={{ padding: "12px 12px 12px 0px" }}>
                                        <CircularProgress size={17} />
                                    </Grid>
                                )
                            }
                            {
                                !spinRefresh && (
                                    <Tooltip title={t("cart.refresh_price_tooltip")}>
                                        <IconButton style={{ float: "right" }} onClick={() => {
                                            dispatch(SetIsBooking(false));
                                            RefreshPrices(false);
                                        }}>
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
            <Grid container alignItems="center" justify="flex-start" spacing={1}>
                <Grid item className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize16 : classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                    {t('cart-material.total') + " (" + t('cart-material.articles') + ") : "}
                </Grid>
                <Grid item className={`${classes.strong} ${!downDesktop ? classes.fontSize16 : classes.fontSize14} ${classes.letterSpacing}`}>
                    {toBookPrice.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost}${currency_cost.currency.symbol}`)}
                </Grid>
            </Grid>
            <Button
                variant={'contained'}
                className={classes.orangeButton}
                fullWidth={true}
                onClick={() => launchProcess()}
                disabled={
                    (toBeBookedProducts ?? []).filter((item) => {
                        return !isProductPackaged({
                            product: item,
                            stackInfos: trip?.stack_info ?? null
                        });
                    }).length === 0 &&
                    bookablePackages.length === 0 &&
                    bookableManualProducts.length === 0
                }
            >
                {
                    user.client_full.type !== 2 || quotationCode === 'beachcomber' ?
                        t('booking.confirm_reservation') :
                        t("cart-material.send-demand-to-book")
                }
            </Button>
        </Card>
    );
});

export default BookingCard;
