import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';

import { SetPublicPrice } from '../../../Actions/Flight';

const useStyles = makeStyles({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontWeight500:{
        fontWeight: 500
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize16:{
        fontSize: 16
    },
    fontSize20:{
        fontSize: 20
    },
    textCenter: {
        textAlign: "center"
    },
    genericPaddingTop: {
        paddingTop: 20
    },
    returnButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
    },
    genericButton: {
        color: "#FFFFFF",
        backgroundColor: "#E6592F"
    },
    fullWidth: {
        width: "100%"
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AcceptPublicPrice = ({ agencyPublicPrice, setAgencyPublicPrice, group_index }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const smallScreen = useMediaQuery('(max-width:425px)');

    const flight_groups = useSelector(store => store.flight.flight_groups);
    const journey_index = useSelector(store => store.flight.journey_index);
    const cancel = () => {
        setAgencyPublicPrice(false);
    }

    const accept = () => {
        if (group_index < flight_groups.length){
            if (journey_index !== null && flight_groups[group_index].journey[journey_index].check_destination) {
                dispatch({type: 'FLIGHT_CHECK_DESTINATION', payload: {group_index: group_index, check_destination: false, journey_index: journey_index}});
            } else {
                let old_value = flight_groups[group_index].public_price
                dispatch(SetPublicPrice(group_index, !old_value));
            }
        }
        setAgencyPublicPrice(false);
    }

    return(
        <Dialog open={agencyPublicPrice} fullScreen={smallScreen ? true : false} TransitionComponent={Transition} maxWidth={"sm"}>
            <DialogContent dividers>
                <Grid className={classes.textCenter}>
                    <WarningIcon style={{color: "orange", fontSize: 110}} />
                </Grid>
                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize20}`}>
                    { t("flight_groups.warning.agency_public_price") }
                </Grid>
                <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize16} ${classes.genericPaddingTop}`}>
                    { t("flight_groups.warning.agency_public_price_terms") }
                </Grid>
                <Grid container direction={smallScreen ? "column" : "row"} alignItems={smallScreen ? "flex-start" : "center"} justify="space-evenly" spacing={smallScreen ? 2 : 0} className={classes.genericPaddingTop}>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.returnButton} onClick={cancel}>
                            { t("global.cancel") }
                        </Button>
                    </Grid>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.genericButton} onClick={accept}>
                            { t("global.read_and_agree") }
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(AcceptPublicPrice);