//Dependencies
import i18n from '../../i18n';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { ArrowDownward, Close, Search } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@material-ui/core/styles';
import { debounce, isEqual, isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

//Core
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
//Functions
import GetProductType from "../Menu/TripList/Functions/GetProductType";
//Components
//Actions

const useStyles = makeStyles({
    validateFullTripPrice: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    orangeSwitchLabel: {
        color: '#E6592F',
        textTransform: 'uppercase',
        fontSize: 14
    },
    orangeBackground: {
        '& .mui-jss-MuiSwitch-track': {
            backgroundColor: '#E6592F',
            opacity: "13%"
        }
    },
    selected: {
        background: '#EDF4FB'
    },
    packagedButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F',
        borderRadius: 15
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "70%",
        left: "50%",
        marginTop: -20,
        marginLeft: -12
    }
});

const CartAutomaticProductsModal = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const automatic_products_open = useSelector(state => state.cart.automatic_products_open);
    const manual_item_list = useSelector(state => state.cart.manual_item_list);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const locales = useSelector(state => state.user.locales);
    const trip = useSelector(state => state.trip.data_trip);

    const [allProducts, setAllProducts] = useState([]);
    const [productToAdd, setProductToAdd] = useState([]);
    const [progress, setProgress] = useState(false);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState('localization__name');
    const [productType, setProductType] = useState([]);
    const previousFilters = useRef({
        limit: 50,
        page: 0,
        search: '',
        productType: [],
        order: 'localization__name'
    });
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const onChangeSearch = useCallback(
        debounce((value) => {
            setSearch(value);
        }, 750),
        [setSearch]
    );

    const onChangeProductType = (event) => {
        setProductType(event.target.value);
    }

    const onChangeOrder = (value) => {
        setOrder((state) => {
            if (!state || state.startsWith('-')) {
                return value;
            }

            return `-${value}`;
        });
    }

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const onClose = () => {
        dispatch({ type: 'CART_TOGGLE_AUTOMATIC_PRODUCTS_MODAL' });
    };
    const onLimitChange = (event) => {
        setLimit(event.target.value);
    };
    const onChangePage = (event, page) => {
        setPage(page);
    };
    const handleSelectChange = (checked) => {
        if (checked) {
            setProductToAdd([...allProducts]);
        } else {
            setProductToAdd([]);
        }
    };
    const getAllProducts = (token) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/auto-products/`,
                cancelToken: token,
                params: {
                    apps_list: true,
                    ordering: (order === 'localization__name' || order === '-localization__name') ? order : undefined,
                    search: search.trim().length > 0 ? search : undefined,
                    product_type__in: productType.length > 0 ? productType.join(',') : undefined,
                    limit: 10000,
                }
            }).then((response) => {
                let parent_destination = [];
                let parent_request = [];
                itinerary_list.map((itinerary) => {
                    if (itinerary.step_type === 'STEP' && !parent_destination.includes(itinerary.destination?.parent?.id)) {
                        parent_destination.push(itinerary.destination?.parent?.id);
                    }
                });
                parent_destination.filter((parent) => {
                    return isNumber(parent);
                }).map((parent) => {
                    parent_request.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            cancelToken: token,
                            url: `${API_HREF}client/${window.id_owner}/destinations/${parent}/breadcrumbs/`
                        })
                    );
                });
                axios.all([...parent_request]).then(axios.spread((...responses) => {
                    let results = response.data.results.filter(result => {
                        let city_id = [];
                        let parent_id = [];
                        let allowed_destinations = false;
                        if (result.allowed_destinations.length === 0) {
                            allowed_destinations = true;
                        } else {
                            console.log('result.allowed_destinations:', result.allowed_destinations);
                            result.allowed_destinations.map((destination) => {
                                console.log('destination:', destination);
                                if (destination.data.type === 4) {
                                    city_id.push(destination.id);
                                } else {
                                    parent_id.push(destination.id);
                                }
                            });
                            console.log('city_id:', city_id);
                            console.log('parent_id:', parent_id);
                            if (city_id.length > 0) {
                                allowed_destinations = itinerary_list.find((itinerary) => {
                                    return itinerary.step_type === "STEP" && city_id.includes(itinerary.destination.id);
                                }) !== undefined;
                            }
                            if (parent_id.length > 0) {
                                const results = parent_id.map((id) => {
                                    return responses.map((parent_response) => {
                                        return checkDestinationParent(parent_response.data, id);
                                    });
                                });
                                console.log('results:', results);
                                results.map((result) => {
                                    result.map(data => {
                                        if (data !== undefined) {
                                            allowed_destinations = true;
                                        }
                                    });
                                });
                            }
                        }
                        return result.is_active && result.no_auto_add && allowed_destinations;
                    });

                    setCount(results.length);
                    setAllProducts(results);
                })).catch(() => {
                    console.log(error);
                });
            }).catch((error) => {
                //notification error
                console.log('error get auto products:', error);
            });
        }
    };
    const checkDestinationParent = (data, id_to_find) => {
        if (data.id !== parseFloat(id_to_find)) {
            if (data.child !== undefined) {
                return (checkDestinationParent(data.child, id_to_find));
            }
            return undefined;
        }
        return true;
    };
    const handleCheckboxClick = (product) => (event) => {
        let product_to_add = [...productToAdd];
        if (event.target.checked) {
            product_to_add.push(product);
        } else {
            product_to_add = product_to_add.filter((item) => item.id !== product.id);
        }
        setProductToAdd(product_to_add);
    };
    const onToggleValidatePackage = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        //const products = productToAdd.filter((item) => {
        //const allowedDestinationIds = item.allowed_destinations.map((item) => {
        //return item.id;
        //}) ?? [];
        //return allowedDestinationIds.length === 0 ||
        //allowedDestinationIds.includes(trip?.destination?.id ?? -1);
        //});
        if (productToAdd.length !== 0 && pass_check) {
            setProgress(true);
            let request_product = [];
            productToAdd.map((product) => {
                request_product.push(
                    axios({
                        method: 'GET',
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/add_automatic_product/?automatic_product_id=${product.id}`,
                        headers: headers
                    })
                );
            });
            axios.all([...request_product]).then(axios.spread((...responses) => {
                console.log('responses:', responses);
                let manual_cart = [...manual_item_list];
                responses.forEach((response) => {
                    manual_cart.push(response.data);
                });
                setProductToAdd([]);
                dispatch({ type: "CART_SET_MANUAL_CART", payload: { manual_cart: manual_cart } });
                setProgress(false);
                dispatch({ type: "CART_TOGGLE_AUTOMATIC_PRODUCTS_MODAL" });
            })).catch((error) => {
                setProgress(false);
                console.log('catch add automatic product error:', error);
            });
        }
        //const notAllowedProducts = productToAdd.filter((item) => {
        //const allowedDestinationIds = item.allowed_destinations.map((item) => {
        //return item.id;
        //}) ?? [];
        //return allowedDestinationIds.length > 0 &&
        //!allowedDestinationIds.includes(trip?.destination?.id ?? -1);
        //});
        //if (notAllowedProducts.length > 0) {
        //enqueueSnackbar(
        //t('cart-material.add-auto-product-destination-error'),
        //{ variant: 'error' }
        //);
        //}
    };
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        if (
            limit !== previousFilters.current.limit ||
            page !== previousFilters.current.page ||
            search !== previousFilters.current.search ||
            !isEqual(productType, previousFilters.current.productType) ||
            order !== 'product_type' ||
            order !== '-product_type'
        ) {
            getAllProducts(token);
        }
        previousFilters.current = {
            limit,
            page,
            search,
            order,
            productType
        };
        return () => cancel();
    }, [
        limit,
        page,
        search,
        order,
        productType
    ]);
    console.log('allProducts:', allProducts);
    return (
        <Dialog open={automatic_products_open} fullWidth maxWidth={'md'} onClose={onClose}>
            <DialogTitle onClose={onClose}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t('cart-material.automatic-products')}
                    </Typography>
                    <IconButton onClick={onClose} style={{ float: 'right' }}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={1} sx={{ marginTop: 3, marginBottom: 1 }}>
                    <TextField
                        size="small"
                        label={t('global.search')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={(event) => onChangeSearch(event.target.value)}
                    />
                    <FormControl size="small" sx={{ minWidth: 250 }}>
                        <InputLabel>{t('shared.product-type')}</InputLabel>
                        <Select
                            label={t('shared.product-type')}
                            variant={'outlined'}
                            value={productType}
                            onChange={onChangeProductType}
                            multiple
                        >
                            <MenuItem value={12}>
                                {t('providers.activities')}
                            </MenuItem>
                            <MenuItem value={8}>{t('providers.insurances')}</MenuItem>
                            {!['verdie', 'cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && <MenuItem value={11}>{t('providers.other_product')}</MenuItem>}
                            {['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && <MenuItem value={11}>{t('providers.other_product_visible')}</MenuItem>}
                            {
                                !['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && (
                                    <MenuItem value={7}>{t('providers.accommodations')}</MenuItem>
                                )
                            }
                            <MenuItem value={17}>{t('providers.travel_book')}</MenuItem>
                            <MenuItem value={9}>{t('providers.cruises')}</MenuItem>
                            {
                                !['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && (
                                    <MenuItem value={1}>{t('providers.excursions')}</MenuItem>
                                )
                            }
                            {
                                !['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && (
                                    <MenuItem value={13}>{t('providers.ferries')}</MenuItem>
                                )
                            }
                            {
                                !['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) ?
                                    <MenuItem value={16}>{t('providers.administration_fees')}</MenuItem>
                                    :
                                    <MenuItem value={16}>{t('providers.administration_fees_other')}</MenuItem>
                            }
                            <MenuItem value={10}>{t('providers.touristic_guide')}</MenuItem>
                            <MenuItem value={0}>{t('providers.hotels')}</MenuItem>
                            <MenuItem value={18}>{t('providers.reduction')}</MenuItem>
                            {
                                !['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && (
                                    <MenuItem value={3}>{t('providers.restaurant')}</MenuItem>
                                )
                            }
                            <MenuItem value={5}>{t('providers.trains')}</MenuItem>
                            <MenuItem value={4}>{t('providers.transfers')}</MenuItem>
                            <MenuItem value={2}>{t('providers.rental_car')}</MenuItem>
                            <MenuItem value={61}>{t('providers.manual_flight')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <TableContainer component={Paper}>
                    <Table sx={{ '& p': { margin: 0 } }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        color={'primary'}
                                        checked={
                                            allProducts &&
                                            allProducts.every((item) => {
                                                return productToAdd.includes(item);
                                            })
                                        }
                                        indeterminate={
                                            productToAdd.length > 0 &&
                                            allProducts &&
                                            !allProducts.every((item) => {
                                                return productToAdd.includes(item);
                                            })
                                        }
                                        onChange={(_, checked) => handleSelectChange(checked)}
                                    />
                                </TableCell>
                                <TableCell onClick={() => onChangeOrder('product_type')} sx={{ cursor: 'pointer' }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="body2" fontWeight={500}>
                                            {t('menu.trip_list.product')}
                                        </Typography>
                                        {
                                            (order === 'product_type' || order === '-product_type') &&
                                            <ArrowDownward
                                                fontSize="small"
                                                sx={(theme) => ({
                                                    transform: order.startsWith('-') ? 'rotate(180deg)' : undefined,
                                                    transition: theme.transitions.create(
                                                        'transform',
                                                        {
                                                            duration: 250,
                                                            easing: theme.transitions.easing.sharp
                                                        }
                                                    ),
                                                    verticalAlign: 'middle'
                                                })}
                                            />
                                        }
                                    </Stack>
                                </TableCell>
                                <TableCell onClick={() => onChangeOrder('localization__name')} sx={{ cursor: 'pointer' }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="body2" fontWeight={500}>
                                            {t('cart-material.name')}
                                        </Typography>
                                        {
                                            (order === 'localization__name' || order === '-localization__name') &&
                                            <ArrowDownward
                                                fontSize="small"
                                                sx={(theme) => ({
                                                    transform: order.startsWith('-') ? 'rotate(180deg)' : undefined,
                                                    transition: theme.transitions.create(
                                                        'transform',
                                                        {
                                                            duration: 250,
                                                            easing: theme.transitions.easing.sharp
                                                        }
                                                    ),
                                                    verticalAlign: 'middle'
                                                })}
                                            />
                                        }
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                [...allProducts].sort((a, b) => {
                                    const aType = GetProductType(a.product_type);
                                    const bType = GetProductType(b.product_type);

                                    if (
                                        order === 'product_type' ||
                                        order === '-product_type'
                                    ) {
                                        if (order.startsWith('-')) {
                                            return aType.localeCompare(bType) * -1;
                                        }
                                        return aType.localeCompare(bType);
                                    }

                                    return 0;
                                }).map((product) => {
                                    let product_type = GetProductType(product.product_type);
                                    let name = '-';
                                    if (product.localization.length !== 0) {
                                        let localization = product.localization.find((el) => {
                                            return el.locale === current_locale.id;
                                        });
                                        if (localization !== undefined) {
                                            name = localization.name;
                                        } else if (product.name !== undefined && product.name !== null) {
                                            name = product.name;
                                        } else {
                                            if (product.product_type === 0) {
                                                name = t('accommodation.hotel') + ' : ' + product.hotel[0].name;
                                            }
                                            if (product.product_type === 6) {
                                                if (product.outbounds !== undefined && product.outbounds !== null) {
                                                    //eslint-disable-next-line max-len
                                                    name = product.outbounds[0].legs[0].marketing_airline.commercial_name + ' : ' + (product.outbounds[0].legs[0].origin !== null && product.outbounds[0].legs[0].destination !== null ? product.outbounds[0].legs[0].origin.airport_code + ' - ' + product.outbounds[0].legs[0].destination.airport_code : product.outbounds[0].legs[0].origin_station.station_code + ' - ' + product.outbounds[0].legs[0].destination_station.station_code);
                                                }
                                            }
                                            if (product.product_type === 11) {
                                                if (product.custom_product !== undefined) {
                                                    name = product.custom_product.title;
                                                }
                                            }
                                        }
                                    } else if (product.name !== undefined && product.name !== null) {
                                        name = product.name;
                                    } else {
                                        if (product.product_type === 0) {
                                            name = t('accommodation.hotel') + ' : ' + product.hotel[0].name;
                                        }
                                        if (product.product_type === 6) {
                                            if (product.outbounds !== undefined && product.outbounds !== null) {
                                                //eslint-disable-next-line max-len
                                                name = product.outbounds[0].legs[0].marketing_airline.commercial_name + ' : ' + (product.outbounds[0].legs[0].origin !== null && product.outbounds[0].legs[0].destination !== null ? product.outbounds[0].legs[0].origin.airport_code + ' - ' + product.outbounds[0].legs[0].destination.airport_code : product.outbounds[0].legs[0].origin_station.station_code + ' - ' + product.outbounds[0].legs[0].destination_station.station_code);
                                            }
                                        }
                                        if (product.product_type === 11) {
                                            if (product.custom_product !== undefined) {
                                                name = product.custom_product.title;
                                            }
                                        }
                                    }
                                    return (
                                        <TableRow key={product.id} className={clsx({ [classes.selected]: productToAdd.find((item) => item.id === product.id) !== undefined })}>
                                            <TableCell>
                                                <Checkbox color={'primary'} checked={productToAdd.find((item) => item.id === product.id) !== undefined} onChange={handleCheckboxClick(product)} />
                                            </TableCell>
                                            <TableCell align={'left'}>{t('providers.' + product_type)}</TableCell>
                                            <TableCell align={'left'}>{name}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={t('menu.trip_list.products_per_page')}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onChangePage={onChangePage}
                                    onChangeRowsPerPage={onLimitChange}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 3.5 }}>
                <Button onClick={onClose}>
                    {t('shared.cancel')}
                </Button>
                <Button disabled={progress || productToAdd.length === 0} variant={"outlined"} onClick={onToggleValidatePackage}>
                    {
                        progress ?
                            <CircularProgress size={24} /> :
                            t('cart-material.save-change')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CartAutomaticProductsModal;
