//Dependencies
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import axios from "axios";
import GetCookie from "../Common/Functions/GetCookie";
import localeText from '../Functions/localeText';
import { CartCancelPrice } from './CartCancelPrice.tsx';

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    dateContainer: {
        textAlign: 'center',
        border: '1px solid'
    },
    cancellationText: {
        whiteSpace: 'pre-wrap'
    },
    content: {
        minHeight: '35vh'
    }
});

const CartAccommodationCancellationDetail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const cancellation_detail = useSelector(state => state.cart.cancellation_detail);
    const cancellation_currency = useSelector(state => state.cart.cancellation_currency);
    const currencies = useSelector(state => state.base.currency_list);
    const [cancellationText, setCancellationText] = useState('');
    const [cancellation, setCancellation] = useState(null);
    const user = useSelector(state => state.user.user);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const locales = useSelector(state => state.user.locales);
    const language = useSelector(state => state.header.tmp_language);
    const main_locale = locales.find(el => el.language_code === language);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        setCancellationText('');
        setCancellation(null);
        dispatch({ type: 'CART_TOGGLE_CANCELLATION_DETAIL', payload: { room: null, currency: null } });
    };
    useEffect(() => {
        if (cancellation_detail !== null) {
            if (cancellation_detail.custom_product !== undefined && cancellation_detail.custom_product !== null && cancellation_detail.custom_product.cancelation !== undefined && cancellation_detail.custom_product.cancelation !== null) {
                let tmp_cancellable = JSON.parse(localeText(main_locale.id, cancellation_detail.custom_product.localization, cancellation_detail.custom_product.cancelation, 'cancelation'));
                tmp_cancellable.refundEligibility = tmp_cancellable.refundEligibility.sort((a, b) => (a.percentageRefundable - b.percentageRefundable) * -1);
                setCancellation(tmp_cancellable);
            } else if (cancellation_detail.cancel_condition_text === null) {
                setCancellationText('');
                const { headers } = CheckBeforeRequest();
                let product_type = '';
                if (cancellation_detail.product_type === 0) {
                    product_type = 'accommodation';
                } else if (cancellation_detail.product_type === 2) {
                    product_type = 'car';
                } else if (cancellation_detail.product_type === 4) {
                    product_type = cancellation_detail.is_custom ? 'custom-products' : 'transfers';
                } else {
                    product_type = 'custom-products';
                }
                axios({
                    method: 'GET',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${product_type}/${cancellation_detail.id}/get_cancellation_text_from_charges/`
                }).then(response => {
                    setCancellationText(response.data.cancellation_text);
                }).catch(error => {
                });
            } else {
                setCancellationText(cancellation_detail.cancel_condition_text);
            }
        }
    }, [cancellation_detail]);
    if (cancellation_detail !== null) {
        let condition = [];
        if (cancellation_detail.product_type === 6 && cancellation_detail.cancel_info !== null && cancellation_detail.cancel_info !== '') {
            condition = cancellation_detail.cancel_info;
            condition = condition.toLowerCase();
            condition = condition.split("--");
        }
        return (
            <Dialog open={true} fullWidth maxWidth={'md'} onClose={onClose}>
                <DialogTitle onClose={onClose}>
                    <Grid container justify={'space-between'} alignItems={'center'}>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={'start'} onClick={onClose}><NavigateBefore /></IconButton> {t('cart-material.cancellation-detail')}
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        {t('cart-material.cancellation-detail')} :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={onClose}><Close /></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={classes.hr} />
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {
                        cancellation_detail.product_type === 6 ? (
                            <Fragment>
                                {
                                    cancellation_detail.refund_detail !== undefined && cancellation_detail.refund_detail !== null && cancellation_detail.refund_detail !== "" &&
                                    <div>
                                        <Typography>{cancellation_detail.refund_detail}</Typography>
                                        <Typography>{cancellation_detail.refund_detail_text}</Typography>
                                        <br />
                                    </div>
                                }
                                {
                                    condition.length > 0 &&
                                    <Fragment>
                                        <Typography><strong>Conditions d'annulation détaillé :</strong></Typography>
                                        {
                                            condition.map((el, el_index) => {
                                                let tmp = el.split("- ");
                                                return (
                                                    <Fragment key={el_index}>
                                                        {
                                                            tmp.map((el1, el1_index) => {
                                                                let nameCapitalized = el1.charAt(0).toUpperCase() + el1.slice(1);
                                                                return (<div key={el1_index}>{nameCapitalized}</div>);
                                                            })
                                                        }
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        ) : (
                            <Fragment>
                                {/*
                                    cancellation_detail.charges.map((condition, index_condition) => {
                                        if (condition.type_charge === "C") {
                                            if (condition.details !== null) {
                                                return <Typography key={ index_condition }>{ condition.details }</Typography>
                                            } else {
                                                if ((condition.from_day === null || condition.from_day === -99) && (condition.to_day !== null && condition.to_day !== -99)) {
                                                    if (parseFloat(condition.amount) === 0) {
                                                        return <Typography key={ index_condition }>Annulation gratuite jusqu'au { moment.utc(cancellation_detail.start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") }</Typography>
                                                    } else {
                                                        return <Typography key={ index_condition }>Jusqu'au { moment.utc(cancellation_detail.start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") } : { parseFloat(condition.amount).toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }{ cancellation_currency.symbol }</Typography>
                                                    }
                                                } else if (condition.from_day !== null && condition.from_day !== -99 && condition.to_day !== null && condition.to_day !== -99) {
                                                    if (parseFloat(condition.amount) === 0) {
                                                        return <Typography key={ index_condition }>Annulation gratuite jusqu'au { moment.utc(cancellation_detail.start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") }</Typography>
                                                    } else {
                                                        return <Typography key={ index_condition }>Du { moment.utc(cancellation_detail.start_date).subtract(condition.from_day, "d").format("DD/MM/YYYY") } au { moment.utc(cancellation_detail.start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") } : { parseFloat(condition.amount).toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }{ cancellation_currency.symbol }</Typography>
                                                    }
                                                } else if ((condition.from_day !== null && condition.from_day !== -99) && (condition.to_day === null || condition.to_day !== -99)) {
                                                    if (parseFloat(condition.amount) === 0) {
                                                        return <Typography key={ index_condition }>Annulation gratuite</Typography>
                                                    } else {
                                                        return <Typography key={ index_condition }>A partir du { moment.utc(cancellation_detail.start_date).subtract(condition.from_day, "d").format("DD/MM/YYYY") } : { parseFloat(condition.amount).toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }{ cancellation_currency.symbol }</Typography>
                                                    }
                                                } else {
                                                    return <Typography key={ index_condition }>No show : { (parseFloat(condition.amount) === 0 ? 'gratuit' : `${ parseFloat(condition.amount).toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }${ cancellation_currency.symbol }`) }</Typography>
                                                }
                                            }
                                        }
                                    })*/
                                    (quotation_code === 'visiteurs' && user.client_full.type === 2) || quotation_code === "tropicalementvotre" ?
                                        <Typography className={classes.cancellationText}>{t("accommodation.cancellable-visiteurs")}</Typography>
                                        : (cancellation !== null ?
                                            <Fragment>
                                                <Typography variant="h6"> {t("quotation.warning_swal")} </Typography>
                                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: cancellation.description }} />
                                                <CartCancelPrice cancellation={cancellation} product={cancellation_detail} />
                                            </Fragment>
                                            :
                                            <Typography className={classes.cancellationText}>{cancellationText}</Typography>
                                        )
                                }
                            </Fragment>
                        )
                    }
                    {
                        (() => {
                            const fee = parseFloat(cancellation_detail?.cancellation_amount ?? '0');
                            const currency = currencies.find((item) => {
                                return item.id === cancellation_detail?.cancellation_currency;
                            });
                            if (fee > 0 && currency) {
                                const price = new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currency: currency?.iso_code ?? 'EUR'
                                    }
                                ).format(fee);
                                return t(
                                    'cart-material.cancellation-fee-dot',
                                    {
                                        amount: price,
                                        date: window.moment.utc(cancellation_detail?.cancellation_date).format('LLL')
                                    }
                                );
                            }
                        })()
                    }
                </DialogContent>
            </Dialog>
        );
    }
    return null;
};

export default CartAccommodationCancellationDetail;
