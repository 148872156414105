import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import axios from 'axios';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { differenceInCalendarYears } from 'date-fns';

const TravelExchangeOptions = ({
    version,
    startDate,
    groups,
    setMealPlanChoise,
    setMealPlan,
    setTravelExchangePrices,
    setSelectedTravelExchangePrices,
    setPriceOptions,
    setCurrencyIso,
    setLoading
}) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const source = axios.CancelToken.source();
        (async () => {
            const formatedGroups = groups?.map((group) => {
                return group.travelers_list.map((traveler) => {
                    return traveler.birth_date ? differenceInCalendarYears(new Date(), new Date(traveler.birth_date)) : 30;
                });
            }) ?? [];
            if (formatedGroups.length > 0 && startDate && version) {
                const { pass_check, headers } = CheckBeforeRequest();
                try {
                    if (setLoading) {
                        setLoading(true);
                    }
                    const response = await axios.get(
                        `${API_HREF}client/${window.id_owner}/circuits/travel_exchange_options/`,
                        {
                            headers,
                            // cancelToken: options.token,
                            params: {
                                trip_version: version,
                                groups: `[${formatedGroups.map((group) => {
                                    return `[${group.join(',')}]`;
                                }).join(',')}]`,
                                is_update_version: false,
                                start_date: startDate.format('YYYY-MM-DD')
                            },
                            cancelToken: source.token
                        }
                    );
                    console.log("travel_exchange_options : ", response.data);
                    if (response.data.travel_exchange_prices.length > 0) {
                        let price = response.data.travel_exchange_prices[0];
                        let meal_plan_choice = [];
                        let default_meal = '';
                        if (price.standard_board !== null) {
                            switch (price.standard_board) {
                                case 'half_board':
                                    default_meal = t('accommodation_filters.half_board');
                                    break;
                                case 'full_board':
                                    default_meal = t('accommodation_filters.all_inclusive');
                                    break;
                                case 'b_and_b':
                                    default_meal = t('accommodation_filters.breakfast_included');
                                    break;
                                case 'inclusive_board':
                                    default_meal = t('accommodation_filters.inclusive_board');
                                    break;
                            }
                            meal_plan_choice.push({ type: default_meal, price: 0 });
                            if (price.full_board_price !== null) {
                                meal_plan_choice.push({ type: t('accommodation_filters.all_inclusive'), price: price.full_board_price });
                            }
                            if (price.bed_and_breakfast_price !== null) {
                                meal_plan_choice.push({ type: t('accommodation_filters.breakfast_included'), price: price.bed_and_breakfast_price });
                            }
                            if (price.inclusive_board_price !== null) {
                                meal_plan_choice.push({ type: t('accommodation_filters.inclusive_board'), price: price.inclusive_board_price });
                            }
                            if (price.half_board_price !== null) {
                                meal_plan_choice.push({ type: t('accommodation_filters.half_board'), price: price.half_board_price });
                            }
    
                            setMealPlanChoise(meal_plan_choice);
                            setMealPlan(default_meal);
                        }
                        setSelectedTravelExchangePrices(response.data.travel_exchange_prices[0]);
                        setTravelExchangePrices(response.data.travel_exchange_prices);
                        setPriceOptions(response.data.travel_exchange_price_options);
                        setCurrencyIso(response.data.travel_exchange_prices[0].currency);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    if (setLoading) {
                        setLoading(false);
                    }
                }
            }
        })();
        return () => {
            source.cancel();
        }
    }, [groups, startDate, version]);
    return (
        <></>
    );
};

export default TravelExchangeOptions;