import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { getCarPicture } from "../../CartMaterial/utils/getCarPicture";
import { getPoiPicture } from "../../CartMaterial/utils/getPoiPicture";
import { getTransferPicture } from "../../CartMaterial/utils/getTransferPicture";
import { getAccommodationPictures } from "../../CartMaterial/utils/getAccommodationPictures";
import { getManualProductPicture } from "../../CartMaterial/utils/getManualProductPicture";
import { findPictureUrl } from "../../CartMaterial/utils/findPictureUrl";
import { Currency } from "../../../Reducers/objects/currency";
import { ProviderBookingProductProps } from "../ProviderBookingProduct";
import { AppState } from "../../../Reducers/Reducers";

type Result = {
    isCustom: boolean,
    type: keyof ReturnType<typeof useCartProducts> | 'manual'
} & ProviderBookingProductProps

export function useProviderBookingProducts(providerId: number): Result[] {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const manualProducts = useSelector((state: AppState) => state.cart.manual_item_list);
    const cart = useCartProducts();
    const getPrice = useGetPrice();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    return useMemo(() => {
        return cart.accommodations.filter((item) => {
            return item.accommodation.provider === providerId &&
                (
                    item.type === 'manual' ||
                    item.accommodation.is_custom
                ) &&
                item.accommodation.is_provider_quotation_displayed;
        }).map((item): Result => {
            let picture = '';

            const pictures = item.type === 'normal' ?
                getAccommodationPictures(item.accommodation) :
                getManualProductPicture(item.accommodation);

            if (pictures[0]) {
                picture = findPictureUrl(pictures[0]);
            }

            const totalCost = item.type === 'normal' ?
                item.accommodation.rooms.reduce(
                    (prev, current) => {
                        const price = getPrice(current.prices);
                        return {
                            cost: prev.cost + price.purchaseCost,
                            currency: price.purchaseCurrency
                        };
                    },
                    { cost: 0, currency: undefined } as { cost: number, currency: Currency | undefined }
                ) :
                (() => {
                    const price = getPrice(item.accommodation.prices);
                    return {
                        cost: price.purchaseCost,
                        currency: price.purchaseCurrency
                    };
                })();

            return {
                id: item.accommodation.id,
                productType: item.accommodation.product_type,
                isCustom: item.type === 'normal' ?
                    item.accommodation.is_custom :
                    false,
                type: item.type === 'normal' ?
                    'accommodations' :
                    'manual',
                status: item.accommodation.booking_status?.status_booking ?? null,
                image: picture,
                startDate: item.accommodation.start_date,
                from: item.accommodation.start_date,
                to: item.accommodation.end_date,
                title: item.type === 'normal' ?
                    item.accommodation.localization.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    item.accommodation.hotel[0]?.name ??
                    '' :
                    item.accommodation.localization?.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    item.accommodation.name,
                bookingNumber: item.accommodation.booking_status?.item_reference ?? '',
                price: new Intl.NumberFormat(
                    i18n.language,
                    {
                        style: 'currency',
                        currency: totalCost.currency?.iso_code ?? 'EUR'
                    }
                ).format(totalCost.cost),
                bookingStatus: item.accommodation.booking_status,
                isProposition: item.accommodation.provider_created,
                destination: item.accommodation.start_destination ?? null,
                providerComment: item.accommodation.provider_booking_comment,
                isManual: item.type === 'manual'
            };
        }).concat(
            cart.cars.filter((item) => {
                return item.car.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.car.is_custom
                    ) &&
                    item.car.is_provider_quotation_displayed;
            }).map((item): Result => {
                let picture = '';

                const pictures = item.type === 'normal' ?
                    getCarPicture(item.car) :
                    getManualProductPicture(item.car);

                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                const price = getPrice(item.car.prices);
                return {
                    id: item.car.id,
                    productType: item.car.product_type,
                    isCustom: item.type === 'normal' ?
                        item.car.is_custom :
                        false,
                    type: item.type === 'normal' ?
                        'cars' :
                        'manual',
                    status: item.car.booking_status?.status_booking ?? null,
                    image: picture,
                    startDate: item.car.start_date,
                    from: item.car.start_date,
                    to: item.car.end_date,
                    title: [
                        item.car.localization?.find((item) => {
                            return item.locale === locale;
                        })?.name ?? item.car.name,
                        quotation_code === 'marcovasco' &&
                        `(${item.car.vehicle_code})`,
                        item.type === 'normal' &&
                        item.car.variant &&
                        ` (${item.car.variant.localization?.find((item) => item.locale === locale)?.title ?? item.car.variant.name})`
                    ].filter((item) => item).join(' '),
                    bookingNumber: item.car.booking_status?.item_reference ?? '',
                    price: new Intl.NumberFormat(
                        i18n.language,
                        {
                            style: 'currency',
                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                        }
                    ).format(price.purchaseCost),
                    bookingStatus: item.car.booking_status,
                    isProposition: item.car.provider_created,
                    destination: item.car.start_destination ?? null,
                    providerComment: item.car.provider_booking_comment,
                    isManual: item.type === 'manual'
                };
            })
        ).concat(
            cart.flights.filter((item) => {
                return item.flight.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.flight.is_custom
                    ) &&
                    item.flight.is_provider_quotation_displayed;
            }).map((item): Result => {
                const price = getPrice(item.flight.prices);
                return {
                    id: item.flight.id,
                    productType: item.flight.product_type,
                    isCustom: item.type === 'normal' ?
                        item.flight.is_custom :
                        false,
                    type: item.type === 'normal' ?
                        'flights' :
                        'manual',
                    status: item.flight.booking_status?.status_booking ?? null,
                    image: '/Img/destination_default.jpg',
                    startDate: item.flight.start_date,
                    from: item.flight.start_date,
                    to: item.flight.end_date,
                    title: item.type === 'normal' ?
                        t(
                            'cart-material.provider-quotation-flight-title',
                            {
                                from: item.flight.outbounds[0]?.legs[0]?.origin?.international_name ??
                                    item.flight.outbounds[0]?.legs[0]?.origin_station?.international_name ??
                                    '',
                                to: item.flight.outbounds[item.flight.outbounds.length - 1]?.legs[
                                    (item.flight.outbounds[item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                ]?.destination?.international_name ??
                                    item.flight.outbounds[item.flight.outbounds.length - 1]?.legs[
                                        (item.flight.outbounds[item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                    ]?.destination_station?.international_name ??
                                    ''
                            }
                        ) :
                        t(
                            'cart-material.provider-quotation-flight-title',
                            {
                                from: item.flight.flight_segment[0]?.origin_airport?.international_name ?? '',
                                to: item.flight.flight_segment[
                                    item.flight.flight_segment.length - 1
                                ]?.destination_airport?.international_name ?? ''
                            }
                        ),
                    bookingNumber: item.flight.booking_status?.item_reference ?? '',
                    price: new Intl.NumberFormat(
                        i18n.language,
                        {
                            style: 'currency',
                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                        }
                    ).format(price.purchaseCost),
                    bookingStatus: item.flight.booking_status,
                    isProposition: item.flight.provider_created,
                    destination: item.flight.start_destination ?? null,
                    providerComment: item.flight.provider_booking_comment,
                    isManual: item.type === 'manual'
                };
            })
        ).concat(
            cart.pois.filter((item) => {
                return item.poi.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.poi.is_custom
                    ) &&
                    item.poi.is_provider_quotation_displayed;
            }).map((item): Result => {
                let picture = '';

                const pictures = item.type === 'normal' ?
                    getPoiPicture(item.poi) :
                    getManualProductPicture(item.poi);

                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                const price = getPrice(item.poi.prices);

                return {
                    id: item.poi.id,
                    productType: item.poi.product_type,
                    isCustom: item.type === 'normal' ?
                        item.poi.is_custom :
                        false,
                    type: item.type === 'normal' ?
                        'pois' :
                        'manual',
                    status: item.poi.booking_status?.status_booking ?? null,
                    image: picture,
                    startDate: item.poi.start_date,
                    from: item.poi.start_date,
                    to: item.poi.end_date,
                    title: (() => {
                        if (item.type === 'normal') {
                            const poiLocalization = item.poi.localization?.find((item) => {
                                return item.locale === locale;
                            });
                            if (poiLocalization) {
                                return poiLocalization.name;
                            }
                            const localization = item.poi.custom_product?.localization?.find((item) => {
                                return item.locale === locale;
                            });
                            if (localization) {
                                return (localization?.title.length ?? 0) > 0 ?
                                    localization?.title ?? '' :
                                    item.poi.custom_product?.title ?? '';
                            }
                            return item.poi.custom_product?.title ?? '';
                        }
                        const poiLocalization = item.poi.localization?.find((item) => {
                            return item.locale === locale;
                        });
                        if (poiLocalization) {
                            return poiLocalization.name;
                        }
                        return item.poi.name;
                    })(),
                    bookingNumber: item.poi.booking_status?.item_reference ?? '',
                    price: new Intl.NumberFormat(
                        i18n.language,
                        {
                            style: 'currency',
                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                        }
                    ).format(price.purchaseCost),
                    bookingStatus: item.poi.booking_status,
                    isProposition: item.poi.provider_created,
                    destination: item.poi.start_destination ?? null,
                    providerComment: item.poi.provider_booking_comment,
                    isManual: item.type === 'manual'
                };
            })
        ).concat(
            cart.transfers.filter((item) => {
                return item.transfer.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.transfer.is_custom
                    ) &&
                    item.transfer.is_provider_quotation_displayed;
            }).map((item): Result => {
                let picture = '';

                const pictures = item.type === 'normal' ?
                    getTransferPicture(item.transfer) :
                    getManualProductPicture(item.transfer);

                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                const price = getPrice(item.transfer.prices);

                return {
                    id: item.transfer.id,
                    productType: item.transfer.product_type,
                    isCustom: item.type === 'normal' ?
                        item.transfer.is_custom :
                        false,
                    type: item.type === 'normal' ?
                        'transfers' :
                        'manual',
                    status: item.transfer.booking_status?.status_booking ?? null,
                    image: picture,
                    startDate: item.transfer.start_date,
                    from: item.transfer.start_date,
                    to: item.transfer.end_date,
                    title: (() => {
                        const localization = item.transfer.localization?.find((item) => {
                            return item.locale === locale;
                        });
                        if (localization) {
                            return localization.name;
                        } else if (
                            item.type === 'normal' &&
                            item.transfer.is_custom
                        ) {
                            return item.transfer.custom_product?.title ?? '';
                        }
                        return item.transfer.name ?? '';
                    })(),
                    bookingNumber: item.transfer.booking_status?.item_reference ?? '',
                    price: new Intl.NumberFormat(
                        i18n.language,
                        {
                            style: 'currency',
                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                        }
                    ).format(price.purchaseCost),
                    bookingStatus: item.transfer.booking_status,
                    isProposition: item.transfer.provider_created,
                    destination: item.transfer.start_destination ?? null,
                    providerComment: item.transfer.provider_booking_comment,
                    isManual: item.type === 'manual',
                    transferInfo: item.type === 'normal' ?
                        {
                            type: item.transfer.transport_type,
                            code: item.transfer.transport_code,
                            company: item.transfer.transport_company,
                            direction: item.transfer.direction,
                            time: item.transfer.transport_time
                        } :
                        undefined
                };
            })
        ).concat(
            manualProducts?.filter((item) => {
                return item.provider === providerId &&
                    item.is_provider_quotation_displayed;
            }).filter((item) => {
                return ![2, 0, 1, 12, 6, 4].includes(item.product_type);
            }).map((item): Result => {
                let picture = '';

                const pictures = getManualProductPicture(item);

                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                const price = getPrice(item.prices);
                return {
                    id: item.id,
                    productType: item.product_type,
                    isCustom: false,
                    type: 'manual',
                    status: item.booking_status?.status_booking ?? null,
                    image: picture,
                    startDate: item.start_date,
                    from: item.start_date,
                    to: item.end_date,
                    title: item.localization?.find((item) => {
                        return item.locale === locale;
                    })?.name ?? item.name ?? '',
                    bookingNumber: item.booking_status?.item_reference ?? '',
                    price: new Intl.NumberFormat(
                        i18n.language,
                        {
                            style: 'currency',
                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                        }
                    ).format(price.purchaseCost),
                    bookingStatus: item.booking_status,
                    isProposition: item.provider_created,
                    destination: item.start_destination ?? null,
                    providerComment: item.provider_booking_comment,
                    isManual: true
                };
            }) ?? []
        ).sort((a, b) => {
            return window.moment.utc(a.startDate).isBefore(
                window.moment.utc(b.startDate)
            ) ? -1 : 1;
        });
    }, [
        t,
        locale,
        i18n.language,
        manualProducts,
        cart,
        getPrice
    ]);
}
