import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { has, isEmpty, isNumber, uniqBy } from "lodash";
import { selectStepTransport } from "../utils/selectStepTransport";
import { createStepFrom } from "../utils/createStepFrom";
import { transformStepInputsToGroups } from "../utils/transformStepInputsToGroups";
import { StepsDatesManager } from "../utils/stepsDatesManager";
import { ItineraryInput, ItineraryState } from "../objects/itineraryState";
import { Transport } from "../objects/transport";
import { LightDestination } from "../objects/lightDestination";
import { Place, Route } from "../objects/r2rSearchResponse";
import { BlockType } from "../objects/blockType";
import { Itinerary } from "../objects/itinerary";

const initialState: ItineraryState = {
    beingCalculatedStep: null,
    beingSavedSteps: {},
    calculatingTransportAtIndex: {},
    loading: false,
    destinations: { state: 'success', data: [], error: null },
    map: null,
    stepsInputs: [],
    lastStartStep: {},
    lastEndStep: {},
    showDestinationsList: true,
    blocks: {
        circuits: { state: 'success', data: [], cache: {}, error: null },
        typicalTrips: { state: 'success', data: [], error: null, cache: [] },
        searching: false,
        filters: {
            types: {
                "circuit": true,
                "package": true,
                "typical-trip": true
            },
            nightsCount: null,
            products: 'all',
            search: ''
        },
        sortBy: {
            field: 'nights-count',
            direction: 'asc'
        },
        lockBoxes: []
    },
    content: {},
    contentInputs: {},
    parentDestination: null,
    destinationsTab: 0,
    adalteModalAddOptions: null,
    travelExchangeModalAddOptions: null
};

const slice = createSlice({
    name: 'itinerarySlice',
    initialState,
    reducers: {
        addBlock: (
            state,
            action: PayloadAction<{
                variant: number | null,
                date: string,
                block: ItineraryInput[],
                startDate: string,
                index: number
            }>
        ): void => {
            const daysDelta = window.moment.utc(
                action.payload.block[0]?.start_date ?? action.payload.startDate
            ).startOf(
                'day'
            ).diff(
                window.moment.utc(action.payload.startDate).startOf('day'),
                'days'
            );
            const steps = action.payload.block.filter((item) => {
                return item.step_type === 'STEP';
            }).map((item) => {
                const startDate = window.moment.utc(item.start_date);
                const endDate = window.moment.utc(item.end_date);
                return {
                    ...item,
                    id: Math.random().toString(),
                    variant: action.payload.variant,
                    circuit_start_date: action.payload.date !== 'PERIOD' ?
                        action.payload.date :
                        null,
                    start_date: window.moment.utc(
                        item.start_date
                    ).startOf(
                        'day'
                    ).subtract(
                        daysDelta,
                        'days'
                    ).set(
                        'hour',
                        startDate.get('hour')
                    ).set(
                        'minute',
                        startDate.get('minute')
                    ).set(
                        'second',
                        startDate.get('second')
                    ).toISOString(),
                    end_date: window.moment.utc(
                        item.end_date
                    ).startOf(
                        'day'
                    ).subtract(
                        daysDelta,
                        'days'
                    ).set(
                        'hour',
                        endDate.get('hour')
                    ).set(
                        'minute',
                        endDate.get('minute')
                    ).set(
                        'second',
                        endDate.get('second')
                    ).toISOString()
                };
            });

            state.stepsInputs.splice(
                action.payload.index,
                0,
                ...steps
            );
        },
        addStepInputFromDestinationUsingIndex: (
            state,
            action: PayloadAction<{
                index: number,
                destination: LightDestination
                tripId: number,
                tripStartDate: string,
                tripEndDate: string,
                version: number,
                daysCount: number
            }>
        ): void => {
            const step = state.stepsInputs[action.payload.index];
            state.stepsInputs.splice(
                action.payload.index + 1,
                0,
                createStepFrom({
                    destination: action.payload.destination,
                    tripId: action.payload.tripId,
                    version: action.payload.version,
                    step: step ?? null,
                    tripStartDate: action.payload.tripStartDate,
                    tripEndDate: action.payload.tripEndDate,
                    daysCount: action.payload.daysCount
                })
            );
        },
        changeBlocksFilter: <K extends keyof ItineraryState['blocks']['filters']>(
            state: ItineraryState,
            action: PayloadAction<{
                key: K,
                value: ItineraryState['blocks']['filters'][K]
            }>
        ): void => {
            state.blocks.filters[action.payload.key] = action.payload.value;
        },
        changeBlocksSort: (
            state,
            action: PayloadAction<ItineraryState['blocks']['sortBy']>
        ): void => {
            state.blocks.sortBy = action.payload;
        },
        changeStepArrivalDate: (
            state,
            action: PayloadAction<{
                stepId: ItineraryInput['id'],
                date: string
            }>
        ): void => {
            const step = state.stepsInputs.find((item) => item.id === action.payload.stepId);
            if (step) {
                step.start_date = action.payload.date;
            }
        },
        changeStepDaysCount: (
            state,
            action: PayloadAction<{
                stepId: ItineraryInput['id'],
                tripStartDate: string,
                tripEndDate: string,
                daysCount: number
            }>
        ): void => {
            const step = state.stepsInputs.find((item) => item.id === action.payload.stepId);
            if (step) {
                const manager = new StepsDatesManager(
                    action.payload.tripStartDate,
                    action.payload.tripEndDate
                );
                const startDate = window.moment.utc(step.start_date);

                let count = action.payload.daysCount;

                if (manager.isLateArrival(startDate.toISOString())) {
                    --count;
                } else if (manager.isLateDeparture(step.end_date)) {
                    ++count;
                }

                const currentEndDate = window.moment.utc(step.end_date);
                step.end_date = startDate.startOf('day').add(
                    count,
                    'days'
                ).set(
                    'hour',
                    currentEndDate.get('hour')
                ).set(
                    'minute',
                    currentEndDate.get('minute')
                ).set(
                    'seconds',
                    currentEndDate.get('seconds')
                ).toISOString();
            }
        },
        changeStepDepartureDate: (
            state,
            action: PayloadAction<{
                stepId: ItineraryInput['id'],
                date: string
            }>
        ): void => {
            const step = state.stepsInputs.find((item) => item.id === action.payload.stepId);
            if (step) {
                step.end_date = action.payload.date;
            }
        },
        changeStepDestination: (
            state,
            action: PayloadAction<{ id: ItineraryInput['id'], destination: LightDestination }>
        ): void => {
            const step = state.stepsInputs.find((item) => {
                return item.id === action.payload.id;
            });
            if (step) {
                step.destination = action.payload.destination;
                step.r2r_json = {};
                step.distance_transport_km = 0;
            }
        },
        changeStepTransport: (
            state,
            action: PayloadAction<{
                step: ItineraryInput,
                nextStep: ItineraryInput,
                fromTimezone: string,
                toTimezone: string,
                nextStepCoordinates: NonNullable<NonNullable<Itinerary['r2r_json']>['nextStepCoordinates']>,
                transport: Transport['alternatives'][number],
                r2rPlaces: Place[],
                r2rRoutes: Route[],
                isCustom: boolean,
                selected?: boolean
            }>
        ): void => {
            const step = state.stepsInputs.find((item) => item.id === action.payload.step.id);
            if (step) {
                selectStepTransport({
                    step,
                    nextStep: action.payload.nextStep,
                    fromTimezone: action.payload.fromTimezone,
                    toTimezone: action.payload.toTimezone,
                    nextStepCoordinates: action.payload.nextStepCoordinates,
                    transport: action.payload.transport,
                    r2rPlaces: action.payload.r2rPlaces,
                    r2rRoutes: action.payload.r2rRoutes,
                    isCustom: action.payload.isCustom
                });
                if (step.r2r_json) {
                    step.r2r_json.selected = false;
                } else {
                    step.r2r_json = { selected: false };
                }
            }
        },
        clearItineraryContent: (
            state
        ): void => {
            state.content = {};
        },
        clearItineraryContentInputs: (
            state
        ): void => {
            state.contentInputs = {};
        },
        createInitialSteps: (
            state,
            action: PayloadAction<{
                tripId: number,
                startDate: string,
                endDate: string,
            }>
        ): void => {
            const starts = state.stepsInputs.filter((item) => item.step_type === 'START');
            const ends = state.stepsInputs.filter((item) => item.step_type === 'END');
            if (starts.length === 0) {
                const start: ItineraryInput = state.lastStartStep[action.payload.tripId] ?
                    {
                        ...state.lastStartStep[action.payload.tripId]!,
                        id: Math.random().toString()
                    } :
                    {
                        id: Math.random().toString(),
                        circuit: null,
                        circuit_trip_version: null,
                        circuit_start_date: null,
                        variant: null,
                        iti_type: null,
                        city_name: null,
                        country_name: null,
                        destination: null,
                        distance_transport_km: 0,
                        duration_transport_after: 0,
                        locked_agency: false,
                        modified_date: new Date().toISOString(),
                        places_id: 'id',
                        r2r_api_version: 'version',
                        r2r_json: {},
                        selected_transport: '',
                        start_date: window.moment.utc(action.payload.startDate).set({
                            hours: 9,
                            minutes: 0,
                            seconds: 0
                        }).toISOString(),
                        end_date: window.moment.utc(action.payload.startDate).set({
                            hours: 9,
                            minutes: 0,
                            seconds: 0
                        }).toISOString(),
                        step_type: 'START',
                        trip: 1,
                        trip_version: 1
                    };
                state.stepsInputs.splice(
                    0,
                    0,
                    start
                );
            } else if (starts.length > 1) {
                state.stepsInputs = [starts[0]!].concat(
                    state.stepsInputs.filter((item) => {
                        return item.step_type !== 'START';
                    })
                );
            }

            if (ends.length === 0) {
                const lastItem = state.stepsInputs[state.stepsInputs.length - 1];
                const end: ItineraryInput = state.lastEndStep[action.payload.tripId] ?
                    {
                        ...state.lastEndStep[action.payload.tripId]!,
                        id: Math.random().toString()
                    } :
                    {
                        id: Math.random().toString(),
                        circuit: null,
                        circuit_trip_version: null,
                        circuit_start_date: null,
                        variant: null,
                        iti_type: null,
                        city_name: null,
                        country_name: null,
                        destination: null,
                        distance_transport_km: 0,
                        duration_transport_after: 0,
                        locked_agency: false,
                        modified_date: new Date().toISOString(),
                        places_id: 'id',
                        r2r_api_version: 'version',
                        r2r_json: {},
                        selected_transport: '',
                        start_date: window.moment.utc(lastItem?.end_date).set({
                            hours: 9,
                            minutes: 0,
                            seconds: 0
                        }).toISOString(),
                        end_date: window.moment.utc(lastItem?.end_date).set({
                            hours: 9,
                            minutes: 0,
                            seconds: 0
                        }).toISOString(),
                        step_type: 'END',
                        trip: 1,
                        trip_version: 1
                    };
                state.stepsInputs.push(end);
            } else if (ends.length > 1) {
                state.stepsInputs = state.stepsInputs.filter((item) => {
                    return item.step_type !== 'END';
                });
                state.stepsInputs.push(ends[0]!);
            }
        },
        copyItineraryContent: (state): void => {
            state.contentInputs = state.content;
        },
        deleteStepsBlock: (
            state,
            action: PayloadAction<{ group: number }>
        ): void => {
            const groups = transformStepInputsToGroups(state.stepsInputs);
            const groupIds = groups[action.payload.group]?.map((item) => item.id) ?? [];
            state.stepsInputs = state.stepsInputs.filter((item) => {
                return !groupIds.includes(item.id);
            });
        },
        deleteStepInput: (
            state,
            action: PayloadAction<{ id: ItineraryState['stepsInputs'][number]['id'] }>
        ): void => {
            const index = state.stepsInputs.findIndex((item) => {
                return item.id === action.payload.id;
            });
            const previousStep = state.stepsInputs[index - 1];
            if (previousStep) {
                const date = window.moment.utc(
                    previousStep.end_date
                ).set(
                    'hours',
                    9
                ).set(
                    'minutes',
                    0
                ).set(
                    'seconds',
                    0
                );

                if (previousStep.step_type === 'START') {
                    previousStep.start_date = date.toISOString();
                    previousStep.end_date = date.toISOString();
                } else if (date.isAfter(window.moment.utc(previousStep.start_date))) {
                    previousStep.end_date = date.toISOString();
                }
            }
            state.stepsInputs = state.stepsInputs.filter((item) => {
                return item.id !== action.payload.id;
            });
        },
        distributeBlockIds: (
            state,
            action: PayloadAction<{
                steps: Itinerary[]
            }>
        ): void => {
            if (action.payload.steps.length === state.stepsInputs.length) {
                for (let i = 0; i < action.payload.steps.length; i++) {
                    const step = action.payload.steps[i]!;
                    const input = state.stepsInputs[i]!;
                    if (
                        step.step_type === 'STEP' &&
                        input.step_type === 'STEP' &&
                        step.destination?.id === input.destination?.id
                    ) {
                        input.circuit = step.circuit;
                        input.circuit_trip_version = step.circuit_trip_version;
                        input.iti_type = step.iti_type;
                    }
                }
            }
        },
        emptyLastStepTransport: (
            state
        ): void => {
            const end = state.stepsInputs[state.stepsInputs.length - 1];
            if (end && !isEmpty(end.r2r_json)) {
                end.r2r_json = {};
            }
        },
        fixInputsAnomalies: (state): void => {
            //remove duplicates of start/end steps
            const start = state.stepsInputs.filter((item) => {
                return item.step_type === 'START';
            }).sort((a) => {
                return a.city_name ?
                    -1 :
                    1;
            });
            const end = state.stepsInputs.filter((item) => {
                return item.step_type === 'END';
            }).sort((a) => {
                return a.city_name ?
                    -1 :
                    1;
            });
            if (start.length > 1 || end.length > 1) {
                state.stepsInputs = state.stepsInputs.filter((item) => {
                    return item.step_type === 'STEP';
                });
                if (start[0]) {
                    state.stepsInputs.unshift(start[0]);
                }

                if (end[0]) {
                    state.stepsInputs.push(end[0]);
                }
            }

            //fix durations
            for (const step of state.stepsInputs) {
                if (
                    step.r2r_json?.duration_string &&
                    step.r2r_json?.duration &&
                    step.r2r_json?.vehicle?.kind !== 'plane' &&
                    step.r2r_json?.duration < 0
                ) {
                    const regex = /^(\d{2})h(\d{2})$/;
                    const matches = step.r2r_json.duration_string.match(regex);
                    if (matches && matches[1] && matches[2]) {
                        const hours = parseInt(matches[1]);
                        const minutes = parseInt(matches[2]);
                        const duration = hours * 60 + minutes;
                        if (Math.abs(duration - step.r2r_json.duration) > 5) {
                            step.r2r_json.duration = duration;
                        }
                    }
                }
            }

            fixDates(state.stepsInputs);

            const startStep = state.stepsInputs.find((item) => {
                return item.step_type === 'START';
            });
            const endStep = state.stepsInputs.find((item) => {
                return item.step_type === 'END';
            });

            if (startStep?.circuit) {
                startStep.circuit = null;
            }

            if (endStep?.circuit) {
                endStep.circuit = null;
            }
        },
        invertStepInputs: (
            state,
            action: PayloadAction<{ hasTripStart: boolean, hasTripEnd: boolean }>
        ): void => {
            const date = window.moment.utc(state.stepsInputs[0]?.start_date);

            if (!state.stepsInputs[0]) {
                date.set('hour', 9);
                date.set('minute', 0);
                date.set('second', 0);
            }

            state.stepsInputs.reverse();

            const firstStep = state.stepsInputs[0];
            const lastStep = state.stepsInputs[state.stepsInputs.length - 1];

            if (firstStep && action.payload.hasTripStart) {
                firstStep.step_type = 'START';
            }

            if (lastStep && action.payload.hasTripEnd) {
                lastStep.step_type = 'END';
            }
        },
        markIndexAsCalculatingTransport: (
            state,
            action: PayloadAction<{ index: number, isCalculating: boolean }>
        ): void => {
            state.calculatingTransportAtIndex[action.payload.index] = action.payload.isCalculating;
        },
        removeInitialSteps: (
            state
        ): void => {
            const from = state.stepsInputs;
            const to = state.stepsInputs.filter((item) => item.step_type === 'STEP');
            if (from.length !== to.length) {
                state.stepsInputs = to;
            }
        },
        setBeingCalculatedStep: (
            state,
            action: PayloadAction<ItineraryState['beingCalculatedStep']>
        ): void => {
            state.beingCalculatedStep = action.payload;
        },
        setBeingSavedStepsdStep: (
            state,
            action: PayloadAction<ItineraryState['beingSavedSteps']>
        ): void => {
            state.beingSavedSteps = action.payload;
        },
        setBlocksCircuits: (
            state,
            action: PayloadAction<Omit<ItineraryState['blocks']['circuits'], 'data' | 'error' | 'cache'> & Partial<Pick<ItineraryState['blocks']['circuits'], 'data' | 'error'>>>
        ): void => {
            switch (action.payload.state) {
                case 'success': {
                    state.blocks.circuits.state = 'success';
                    state.blocks.circuits.data = action.payload.data ?? [];
                    state.blocks.circuits.error = null;
                    break;
                }
                case 'loading': {
                    state.blocks.circuits.state = 'loading';
                    state.blocks.circuits.error = null;
                    break;
                }
                case 'error': {
                    state.blocks.circuits.state = 'error';
                    state.blocks.circuits.error = action.payload.error ?? null;
                    break;
                }
            }
        },
        setBlocksCircuitsCache: (
            state,
            action: PayloadAction<{
                circuitId: number,
                source: string | null,
                versionId: number,
                data: ItineraryState['blocks']['circuits']['cache'][number][number]
            }>
        ): void => {
            if (!state.blocks.circuits.cache[action.payload.circuitId]) {
                state.blocks.circuits.cache[action.payload.circuitId] = {
                    id: action.payload.circuitId,
                    source: action.payload.source,
                };
            }
            state.blocks.circuits.cache[action.payload.circuitId]![action.payload.versionId] = action.payload.data;
        },
        setBlocksTypicalTrips: (
            state,
            action: PayloadAction<Omit<ItineraryState['blocks']['typicalTrips'], 'data' | 'error' | 'cache'> & Partial<Pick<ItineraryState['blocks']['typicalTrips'], 'data' | 'error'>>>
        ): void => {
            switch (action.payload.state) {
                case 'success': {
                    state.blocks.typicalTrips.state = 'success';
                    state.blocks.typicalTrips.data = (action.payload.data ?? []).map((item) => {
                        if (
                            Object.keys(item).length === 1 &&
                            has(item, 'id')
                        ) {
                            return state.blocks.typicalTrips.cache.find((cachedItem) => {
                                return cachedItem.id === item.id;
                            });
                        }
                        return item;
                    }).filter((item): item is NonNullable<typeof item> => {
                        return !!item;
                    });
                    state.blocks.typicalTrips.cache = uniqBy(
                        state.blocks.typicalTrips.cache.concat(
                            action.payload.data?.filter((item) => {
                                return Object.keys(item).length > 1;
                            }) ?? []
                        ),
                        (item) => item.id
                    );
                    state.blocks.typicalTrips.error = null;
                    break;
                }
                case 'loading': {
                    state.blocks.typicalTrips.state = 'loading';
                    state.blocks.typicalTrips.error = null;
                    break;
                }
                case 'error': {
                    state.blocks.typicalTrips.state = 'error';
                    state.blocks.typicalTrips.error = action.payload.error ?? null;
                    break;
                }
            }
        },
        setAdalteModalAddOptions: (
            state,
            action: PayloadAction<ItineraryState['adalteModalAddOptions']>
        ): void => {
            state.adalteModalAddOptions = action.payload;
        },
        setBothDepartureReturnDestinations: (
            state,
            action: PayloadAction<google.maps.places.PlaceResult | null>
        ): void => {
            const departure = state.stepsInputs.find((item) => {
                return item.step_type === 'START';
            });

            if (departure) {
                let city = '';
                let country = '';

                if (action.payload?.address_components) {
                    action.payload.address_components.forEach(function (c) {
                        switch (c.types[0]) {
                            case 'neighborhood':
                            case 'locality':
                            case 'airport':
                                city = c.long_name;
                                break;
                            case 'country':
                                country = c.long_name;
                                break;
                        }
                    });
                    if (city === '') {
                        city = action.payload.name ?? '';
                    }
                }

                departure.city_name = city;
                departure.country_name = country;
                departure.places_id = action.payload?.place_id ?? '';
                departure.r2r_json = {};
                departure.distance_transport_km = 0;
            }

            const returnStepIndex = state.stepsInputs.findIndex((item) => {
                return item.step_type === 'END';
            });
            const returnStep = state.stepsInputs[returnStepIndex];

            if (returnStep) {
                let city = '';
                let country = '';

                if (action.payload?.address_components) {
                    action.payload.address_components.forEach(function (c) {
                        switch (c.types[0]) {
                            case 'neighborhood': case 'locality': case 'airport':
                                city = c.long_name;
                                break;
                            case 'country':
                                country = c.long_name;
                                break;
                        }
                    });
                    if (city === '') {
                        city = action.payload.name ?? '';
                    }
                }

                returnStep.city_name = city;
                returnStep.country_name = country;
                returnStep.places_id = action.payload?.place_id ?? '';

                const previousStep = state.stepsInputs[returnStepIndex - 1];

                if (previousStep) {
                    previousStep.r2r_json = {};
                    previousStep.distance_transport_km = 0;
                }
            }
        },
        setDefaultArrivalHourlyForTerrestrialOnlyMode: (
            state
        ): void => {
            const firstStep = state.stepsInputs.find((item) => {
                return item.step_type === 'STEP';
            });
            if (firstStep) {
                const date = window.moment.utc(firstStep.start_date);
                date.set('hours', 9);
                date.set('minutes', 0);
                date.set('seconds', 0);
                firstStep.start_date = date.toISOString();
            }
        },
        setDepartureDestination: (
            state,
            action: PayloadAction<google.maps.places.PlaceResult | null>
        ): void => {
            const departure = state.stepsInputs.find((item) => {
                return item.step_type === 'START';
            });

            if (departure) {
                let city = '';
                let country = '';

                if (action.payload?.address_components) {
                    action.payload.address_components.forEach(function (c) {
                        switch (c.types[0]) {
                            case 'neighborhood':
                            case 'locality':
                            case 'airport':
                                city = c.long_name;
                                break;
                            case 'country':
                                country = c.long_name;
                                break;
                        }
                    });
                    if (city === '') {
                        city = action.payload.name ?? '';
                    }
                }

                departure.city_name = city;
                departure.country_name = country;
                departure.places_id = action.payload?.place_id ?? '';
                departure.r2r_json = {};
                departure.distance_transport_km = 0;
            }
        },
        setDestinations: (
            state,
            action: PayloadAction<Omit<ItineraryState['destinations'], 'data' | 'error'> & Partial<Pick<ItineraryState['destinations'], 'data' | 'error'>>>
        ): void => {
            switch (action.payload.state) {
                case 'success': {
                    state.destinations.state = 'success';
                    state.destinations.data = action.payload.data ?? [];
                    state.destinations.error = null;
                    break;
                }
                case 'loading': {
                    state.destinations.state = 'loading';
                    state.destinations.error = null;
                    break;
                }
                case 'error': {
                    state.destinations.state = 'error';
                    state.destinations.error = action.payload.error ?? null;
                    break;
                }
            }
        },
        setDestinationsTab: (
            state,
            action: PayloadAction<ItineraryState['destinationsTab']>
        ): void => {
            state.destinationsTab = action.payload;
        },
        setItineraryContent: (
            state,
            action: PayloadAction<{
                version: number,
                data: ItineraryState['content'][number]
            }>
        ): void => {
            state.content[action.payload.version] = action.payload.data;
        },
        setItineraryContentInputs: (
            state,
            action: PayloadAction<{
                version: number,
                data: ItineraryState['content'][number]
            }>
        ): void => {
            state.contentInputs[action.payload.version] = action.payload.data;
        },
        setItineraryStartDate: (
            state,
            action: PayloadAction<{ startDate: string }>
        ): void => {
            const start = state.stepsInputs[0];

            //always set start step's date to start of trip
            if (
                start &&
                (
                    !window.moment.utc(start.start_date).isSame(window.moment.utc(action.payload.startDate)) ||
                    window.moment.utc(start.end_date).isBefore(window.moment.utc(action.payload.startDate))
                )
            ) {
                if (start.step_type === 'START') {
                    const endDate = window.moment.utc(start.end_date);
                    const date = window.moment.utc(
                        action.payload.startDate
                    ).set(
                        'hours',
                        endDate.get('hours')
                    ).set(
                        'minutes',
                        endDate.get('minutes')
                    ).set(
                        'seconds',
                        endDate.get('seconds')
                    );
                    start.end_date = date.toISOString();
                    start.start_date = date.startOf('day').toISOString();
                } else if (
                    start.step_type === 'STEP' &&
                    (
                        !start.circuit_start_date ||
                        !window.moment.utc(start.circuit_start_date, 'YYYY-MM-DD').startOf('day').isSame(
                            window.moment.utc(start.start_date).startOf('day'),
                            'days'
                        )
                    )
                ) {
                    const stepStartDate = window.moment.utc(start.start_date);
                    const stepEndDate = window.moment.utc(start.end_date);
                    let startDate = window.moment.utc(action.payload.startDate).startOf('day');

                    if (
                        start.circuit_start_date &&
                        !stepStartDate.clone().startOf('day').isSame(
                            window.moment.utc(start.circuit_start_date, 'YYYY-MM-DD').startOf('day'),
                            'days'
                        )
                    ) {
                        startDate = window.moment.utc(start.circuit_start_date, 'YYYY-MM-DD');
                        startDate.set({
                            hour: stepEndDate.get('hour'),
                            minute: stepEndDate.get('minute'),
                            second: stepEndDate.get('second')
                        });
                    }

                    const delta = Math.abs(
                        Math.floor(
                            window.moment.utc(
                                start.start_date
                            ).startOf(
                                'day'
                            ).diff(
                                window.moment.utc(start.end_date).startOf('day'),
                                'days'
                            )
                        )
                    );
                    const endDate = startDate.clone().add(
                        delta,
                        'days'
                    );

                    endDate.set(
                        'hours',
                        stepEndDate.get('hours')
                    ).set(
                        'minutes',
                        stepEndDate.get('minutes')
                    ).set(
                        'seconds',
                        stepEndDate.get('seconds')
                    );

                    if (startDate.isAfter(endDate)) {
                        endDate.set(
                            'hours',
                            startDate.get('hours')
                        ).set(
                            'minutes',
                            startDate.get('minutes')
                        ).set(
                            'seconds',
                            startDate.get('seconds')
                        );
                    }
                    startDate.set(
                        'hours',
                        stepStartDate.get('hours')
                    );
                    startDate.set(
                        'minutes',
                        stepStartDate.get('minutes')
                    );
                    startDate.set(
                        'seconds',
                        stepStartDate.get('seconds')
                    );
                    start.start_date = startDate.toISOString();
                    start.end_date = endDate.toISOString();
                }
            }
        },
        setLastEndStep: (
            state,
            action: PayloadAction<{ tripId: number, step: ItineraryInput }>
        ): void => {
            state.lastEndStep[action.payload.tripId] = action.payload.step;
        },
        setLastStartStep: (
            state,
            action: PayloadAction<{ tripId: number, step: ItineraryInput }>
        ): void => {
            state.lastStartStep[action.payload.tripId] = action.payload.step;
        },
        setLoading: (
            state,
            action: PayloadAction<ItineraryState['loading']>
        ): void => {
            state.loading = action.payload;
        },
        setLockboxes: (
            state,
            action: PayloadAction<ItineraryState['blocks']['lockBoxes']>
        ): void => {
            state.blocks.lockBoxes = action.payload;
        },
        setMap: (
            state,
            action: PayloadAction<ItineraryState['map']>
        ): void => {
            state.map = action.payload;
        },
        setNightsCountFilter: (
            state,
            action: PayloadAction<ItineraryState['blocks']['filters']['nightsCount']>
        ): void => {
            state.blocks.filters.nightsCount = action.payload;
        },
        setParentDestination: (
            state,
            action: PayloadAction<ItineraryState['parentDestination']>
        ): void => {
            state.parentDestination = action.payload;
        },
        setProductsFilter: (
            state,
            action: PayloadAction<ItineraryState['blocks']['filters']['products']>
        ): void => {
            state.blocks.filters.products = action.payload;
        },
        setReturnDestination: (
            state,
            action: PayloadAction<google.maps.places.PlaceResult | null>
        ): void => {
            const returnStepIndex = state.stepsInputs.findIndex((item) => {
                return item.step_type === 'END';
            });
            const returnStep = state.stepsInputs[returnStepIndex];

            if (returnStep) {
                let city = '';
                let country = '';

                if (action.payload?.address_components) {
                    action.payload.address_components.forEach(function (c) {
                        switch (c.types[0]) {
                            case 'neighborhood': case 'locality': case 'airport':
                                city = c.long_name;
                                break;
                            case 'country':
                                country = c.long_name;
                                break;
                        }
                    });
                    if (city === '') {
                        city = action.payload.name ?? '';
                    }
                }

                returnStep.city_name = city;
                returnStep.country_name = country;
                returnStep.places_id = action.payload?.place_id ?? '';

                const previousStep = state.stepsInputs[returnStepIndex - 1];

                if (previousStep) {
                    previousStep.r2r_json = {};
                    previousStep.distance_transport_km = 0;
                }
            }
        },
        setSearching: (
            state,
            action: PayloadAction<ItineraryState['blocks']['searching']>
        ): void => {
            state.blocks.searching = action.payload;
        },
        setSearchFilter: (
            state,
            action: PayloadAction<ItineraryState['blocks']['filters']['search']>
        ): void => {
            state.blocks.filters.search = action.payload;
        },
        setStepsInputs: (
            state,
            action: PayloadAction<ItineraryState['stepsInputs']>
        ): void => {
            state.stepsInputs = action.payload;
        },
        setTravelExchangeModalAddOptions: (
            state,
            action: PayloadAction<ItineraryState['travelExchangeModalAddOptions']>
        ): void => {
            state.travelExchangeModalAddOptions = action.payload;
        },
        swapBlock: (
            state,
            action: PayloadAction<{
                group: number,
                index: number
            }>
        ): void => {
            const groups = transformStepInputsToGroups(state.stepsInputs);
            const group = groups[action.payload.group] ?? [];
            const groupIds = group.map((item) => item.id);
            const firstGroupElementIndex = state.stepsInputs.findIndex((item) => {
                return item.circuit && item.circuit === group[0]?.circuit;
            });

            const result: ItineraryInput[] = [];
            //a swap from bottom to top
            if (firstGroupElementIndex > action.payload.index) {
                for (let i = 0; i < action.payload.index; i++) {
                    result.push(state.stepsInputs[i]!);
                }
                result.push(...group);
                result.push(
                    ...state.stepsInputs.slice(action.payload.index).filter((item) => {
                        return !groupIds.includes(item.id);
                    })
                );
                //a swap from top to bottom
            } else {
                result.push(
                    ...state.stepsInputs.slice(0, action.payload.index).filter((item) => {
                        return !groupIds.includes(item.id);
                    })
                );
                result.push(...group);
                for (let i = action.payload.index; i < state.stepsInputs.length; i++) {
                    result.push(state.stepsInputs[i]!);
                }
            }

            state.stepsInputs = result;
        },
        swapStepsInputs: (
            state,
            action: PayloadAction<{
                id: ItineraryState['stepsInputs'][number]['id'],
                index: number
            }>
        ): void => {
            const itemIndex = state.stepsInputs.findIndex((item) => {
                return item.id === action.payload.id;
            });
            const item = state.stepsInputs[itemIndex];
            if (item) {
                if (itemIndex > action.payload.index) {
                    state.stepsInputs.splice(action.payload.index, 0, item);
                    state.stepsInputs.splice(itemIndex + 1, 1);
                } else if (itemIndex < action.payload.index) {
                    state.stepsInputs.splice(action.payload.index, 0, item);
                    state.stepsInputs.splice(itemIndex, 1);
                }
            }
        },
        toggleBlocksTypeFilter: (
            state,
            action: PayloadAction<{
                type: BlockType
            }>
        ): void => {
            state.blocks.filters.types[action.payload.type] = !state.blocks.filters.types[action.payload.type];
        },
        toggleShowDestinationsList: (
            state
        ): void => {
            state.showDestinationsList = !state.showDestinationsList;
        },
        ungroupBlock: (
            state,
            action: PayloadAction<{ group: number }>
        ): void => {
            const groups = transformStepInputsToGroups(state.stepsInputs);
            const group = groups[action.payload.group] ?? [];
            for (const step of group) {
                step.circuit = null;
                step.iti_type = null;
            }
        }
    }
});

export default slice;

export const {
    addBlock,
    addStepInputFromDestinationUsingIndex,
    changeBlocksFilter,
    changeBlocksSort,
    changeStepDaysCount,
    changeStepArrivalDate,
    changeStepDepartureDate,
    changeStepDestination,
    changeStepTransport,
    clearItineraryContent,
    clearItineraryContentInputs,
    createInitialSteps,
    copyItineraryContent,
    deleteStepsBlock,
    deleteStepInput,
    distributeBlockIds,
    emptyLastStepTransport,
    fixInputsAnomalies,
    invertStepInputs,
    markIndexAsCalculatingTransport,
    removeInitialSteps,
    setBeingCalculatedStep,
    setBeingSavedStepsdStep,
    setBlocksCircuits,
    setBlocksCircuitsCache,
    setBlocksTypicalTrips,
    setAdalteModalAddOptions,
    setBothDepartureReturnDestinations,
    setDefaultArrivalHourlyForTerrestrialOnlyMode,
    setDepartureDestination,
    setDestinations,
    setDestinationsTab,
    setItineraryContent,
    setItineraryContentInputs,
    setItineraryStartDate,
    setLastEndStep,
    setLastStartStep,
    setLoading,
    setLockboxes,
    setMap,
    setNightsCountFilter,
    setParentDestination,
    setProductsFilter,
    setReturnDestination,
    setSearching,
    setSearchFilter,
    setStepsInputs,
    setTravelExchangeModalAddOptions,
    swapBlock,
    swapStepsInputs,
    toggleBlocksTypeFilter,
    toggleShowDestinationsList,
    ungroupBlock
} = slice.actions;

function fixDates(
    inputs: ItineraryInput[]
): void {
    for (let i = 0; i < inputs.length; i++) {
        const current = inputs[i]!;
        const next = inputs[i + 1];

        if (
            next &&
            isNumber(next.circuit) &&
            (
                current.circuit !== next.circuit ||
                current.circuit_trip_version !== next.circuit_trip_version
            ) &&
            next.circuit_start_date &&
            window.moment.utc(next.circuit_start_date, 'YYYY-MM-DD').startOf('day').isSame(
                window.moment.utc(next.start_date).startOf('day'),
                'days'
            )
        ) {
            continue;
        }

        const currentDepartureDate = window.moment.utc(current.end_date);
        const currentStepDelta = Math.abs(
            window.moment.utc(
                current.end_date
            ).startOf(
                'day'
            ).diff(
                window.moment.utc(current.start_date).startOf('day'),
                'days'
            )
        );

        //start and end step should always be 0N
        if (currentStepDelta > 0) {
            if (current.step_type === 'START') {
                const end = window.moment.utc(current.end_date);
                current.end_date = window.moment.utc(
                    current.start_date
                ).set(
                    'hours',
                    end.get('hours')
                ).set(
                    'minutes',
                    end.get('minutes')
                ).set(
                    'seconds',
                    end.get('seconds')
                ).toISOString();
            } else if (current.step_type === 'END') {
                current.end_date = current.start_date;
            }
        }

        if (
            next &&
            (
                window.moment.utc(next.start_date).isBefore(currentDepartureDate) ||
                window.moment.utc(next.start_date).isAfter(window.moment.utc(next.end_date))
            )
        ) {
            const endDate = window.moment.utc(next.end_date);
            const delta = Math.abs(
                window.moment.utc(
                    next.end_date
                ).startOf(
                    'day'
                ).diff(
                    window.moment.utc(next.start_date).startOf('day'),
                    'days'
                )
            );
            next.start_date = current.end_date;
            next.end_date = window.moment.utc(
                current.end_date
            ).startOf(
                'day'
            ).add(
                delta,
                'days'
            ).set(
                'hours',
                endDate.get('hours')
            ).set(
                'minutes',
                endDate.get('minutes')
            ).set(
                'seconds',
                endDate.get('seconds')
            ).toISOString();
        }
    }
}
