const initial_state = {
    modal: false,
    modal_signin: false,
    step: 1,
    destinations: [],
    full_destination: [],
    filters_type: [],
    filter_destinations: [],
    filter_categories: [],
    guide_languages: [],
    list_type: 'card'
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.home !== undefined) {
                Object.keys(action.payload.home).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.home[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "HOME_TOGGLE_MODAL": {
            new_state.modal = action.payload.modal;
            new_state.step = action.payload.step;
            return new_state;
        }
        case "HOME_TOGGLE_MODAL_SIGN_IN": {
            new_state.modal_signin = action.payload.modal_signin;
            return new_state;
        }
        case "HOME_SET_DESTINATIONS": {
            new_state.destinations = action.payload.destinations;
            return new_state;
        }
        case "HOME_SET_STEP": {
            new_state.step = action.payload.step;
            return new_state;
        }
        case "HOME_SET_FULL_DESTINATIONS": {
            return {...state, full_destination: action.payload}
        }
        case "HOME_SET_FILTERS_TYPE": {
            return {...state, filters_type: action.payload}
        }
        case "HOME_RESET_FILTERS": {
            return {...state, filters_type: [], filter_destinations: [], filter_categories: []}
        }
        case "HOME_LIST_TYPE": {
            return {...state, list_type: action.payload}
        }
        case "HOME_SET_FILTER_DESTINATIONS": {
            return {...state, filter_destinations: action.payload}
        }
        case "HOME_SET_FILTER_CATEGORIES": {
            return {...state, filter_categories: action.payload}
        }
        case "HOME_SET_GUIDE_LANGUAGES": {
            return {...state, guide_languages: action.payload}
        }
    }
    return new_state;
}