import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import PatchTravelers from './PatchTravelers';
import axios from "axios";
import GetCookie from "../../Common/Functions/GetCookie";
import SetCookie from "../../Common/Functions/SetCookie";
import GetTripMenu from "../../Menu/LeftMenuPanel/Functions/GetTrip.js";

import { Flush } from "../../../Actions/Flush";
import { ToggleAndSetLanguage } from '../../../Actions/Header';
import i18n from '../../../i18n.jsx';
import { SetTravelers } from '../../../Actions/Trip';
import { getQuotation } from './getQuotation';
import moment from 'moment';
import { FlightRedirection } from './FlightRedirection.ts';
import UpdateCart from '../../Itinerary/Functions/UpdateCart.js';
let traveler_group = [];

function redirection(data, active_page, trip_list_ordering, trip_list_search, trip_list_filter, groups, user, dispatch, router, flight_circuit, startAirport, cabin, providers, itinerary, adalteRooms) {
    if (traveler_group.length === groups.length) {
        dispatch(Flush());
        GetTripMenu(active_page, trip_list_ordering, trip_list_search, trip_list_filter, dispatch, window.id_owner);
        // UpdateCart("COMP", dispatch, null, null, traveler_group);
        if (startAirport !== undefined && startAirport !== null && startAirport !== '') {
            FlightRedirection(data, flight_circuit, startAirport, groups, itinerary, cabin, providers, user, router, dispatch);
        } else {
            dispatch({
                type: "MENU_FROM_DUPLICATION",
                payload: {
                    from_duplication: true
                }
            });
            router.push(`/${window.url_name}/apps/itinerary`);
        }
    }
}

const SetDataToRedirect = (data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms) => {
    let { pass_check, headers } = CheckBeforeRequest();

    let user_token = GetCookie("token");
    let id_user = GetCookie("id_user");
    let client_user = GetCookie("client_user");
    const crmCookies = {
        crm_token: GetCookie("crm_token"),
        crm_id_user: GetCookie("crm_id_user"),
        crm_client_user: GetCookie("crm_client_user"),
        isFromCrm: GetCookie("isFromCrm")
    };
    let current_version = data.current_version;
    //Delete the cookie
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
            let p = location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
    SetCookie("trip_id", data.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("trip_id_version", current_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("trip_token", data.token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    if (crmCookies.crm_token) {
        for (const key in crmCookies) {
            SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        }
    }
    dispatch(Flush());
    dispatch(ToggleAndSetLanguage(false, i18n.language));

    PatchTravelers(tempTravelers, tempContactLead, withApplicant, headers, 'duplicate', dispatch, enqueueSnackbar, t);


    axios({
        method: "GET",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}&ordering=-default&limit=10000`
    }).then((response1) => {
        let travelers_group_data = response1.data.results.slice();
        let request_travelers = {
            travelers: [],
            expected_deposit: null,
            has_manual_expected_deposit: false
        };
        tempTravelers.map((traveler) => {
            request_travelers.travelers.push(traveler.id);
        });
        if (withApplicant) {
            request_travelers.contact_traveler = tempContactLead.id;
        }
        for (let i = 0; i < travelers_group_data.length; i++) {
            if (travelers_group_data[i].default) {
                if (pass_check) {
                    let request = {
                        default: false
                    };
                    axios({
                        method: "PATCH",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/${travelers_group_data[i].id}/`,
                        data: JSON.stringify(request)
                    }).catch((errorPatchTravelerGroup) => {
                        console.log('errorPatchTravelerGroup:', errorPatchTravelerGroup);
                    });
                }
            }
        }
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
            data: JSON.stringify(request_travelers)
        }).then((response2) => {
            console.log('response2:', response2);
            let data = Object.assign({}, response2.data);
            let end_date = data.end_date;
            $.each(data.travelers, function (index_travelers, value_travelers) {
                let age = moment(end_date).diff(moment(value_travelers.birth_date), "y");
                if (age >= 12) {
                    value_travelers.flight_type = "ADT";
                } else if (age >= 2) {
                    value_travelers.flight_type = "CNN";
                } else {
                    value_travelers.flight_type = "INF";
                }
                value_travelers.passenger_group = null;
            });
            dispatch(SetTravelers(data.travelers));
            traveler_group = [];
            for (let i = 0; i < groups.length; i++) {
                if (pass_check) {
                    let postRequest = {
                        default: true,
                        travelers: groups[i].travelers
                    };
                    axios({
                        method: "POST",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/`,
                        data: JSON.stringify(postRequest)
                    }).then((postGroup) => {
                        traveler_group.push(postGroup.data.id);
                        redirection(data, active_page, trip_list_ordering, trip_list_search, trip_list_filter, groups, user, dispatch, router, flight_circuit, startAirport, cabin, providers, itinerary, adalteRooms);
                    }).catch((postTravelerGroup) => {
                        console.log('postTravelerGroup:', postTravelerGroup);
                    });
                }
            }
        });
    }).catch((errorTravelerGroup) => {
        console.log('errorTravelerGroup:', errorTravelerGroup);
    });
};

const PatchUserTo = (data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms) => {
    let { pass_check, headers } = CheckBeforeRequest();
    axios({
        method: "PATCH",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/trip/${data.id}/`,
        data: {
            user_to: user.is_bo_user ? user.id : null,
            user_ta: user.is_seller_user ? user.id : null
        }
    }).then((response) => {
        SetDataToRedirect(response.data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms);
    }).catch((error) => {
        console.log('error patch agent bo:', error);
    });
};

const DuplicateTrip = (agencyFilter, pgiRef, tripName, tempTravelers, tempContactLead, withApplicant, groups, trip_id, trip_version, startDate, user, selectedVariant, selectedOptions, isFrom, active_page, trip_list_ordering, trip_list_search, trip_list_filter, startDestination, returnDestination, selectNight, dispatch, router, enqueueSnackbar, t, destination, parent, periodId, is_custom, end_date, travelExchangePrices, selectedTravelExchangeOptions, mealPlan, flight_circuit, startAirport, itinerary, cabin, providers, adalteRooms, is_by_day_view, pickups, adalteLanguageCode) => {
    let { pass_check, headers } = CheckBeforeRequest();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    let trip_index = trip_id;
    let version = trip_version;
    let adults = 0;
    let children = 0;
    let singleRoom = 0;
    let twinRoom = 0;
    let twinShare = 0;
    let tripleRoom = 0;
    let quadRoom = 0;
    tempTravelers.map((traveler) => {
        if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18) {
            adults++;
        } else {
            children++;
        }
    });
    let url = `${API_HREF}client/${window.id_owner}/trip/duplication/?id=${trip_index}&start_date=${moment(startDate).format("YYYY-MM-DD")}&trip_type=0`;
    if (version !== undefined && version !== null) {
        url += "&version_id=" + version;
    }
    if (agencyFilter !== null) {
        url += "&new_client=" + agencyFilter.id;
    } else if (quotation_code === "marcovasco") {
        url += "&new_client=22873";
    }
    if (user.client_full.type === 2 && agencyFilter === null && quotation_code !== "marcovasco") {
        url += "&new_client=" + user.client_full.id;
    }
    if (user.client_full.type !== 2) {
        if (pgiRef !== "") {
            url += "&pgi_ref=" + pgiRef;
        }
        //if (note !== "") {
        //url += "&note=" + note;
        //}
        if (tripName !== "") {
            url += "&trip_name=" + tripName;
        }
    }
    if (isFrom !== "tripList" && isFrom !== "ItineraryType") {
        let all_product_to_add = [];
        all_product_to_add = all_product_to_add.concat(selectedVariant);
        if (selectedOptions.length !== 0) {
            all_product_to_add = all_product_to_add.concat(selectedOptions);
        }
        for (let i = 0; i < all_product_to_add.length; i++) {
            url += "&pid=" + all_product_to_add[i];
        }
    }
    if (selectNight !== undefined && selectNight !== null && selectNight !== "") {
        url += "&nb_days=" + selectNight;
    }
    if (periodId !== undefined && periodId !== null) {
        url+= `&circuit_period_id=${periodId.id}`
    }
    let start_step = null;
    let end_step = null;
    if (startDestination !== null) {
        start_step = {
            city_name: startDestination.structured_formatting.main_text,
            country_name: startDestination.structured_formatting.secondary_text,
            step_type: "START",
            places_id: startDestination.reference,
            duration_transport_after: 0,
            selected_transport: "",
            distance_transport_km: 0,
            locked_agency: false,
            r2r_api_version: "version",
            r2r_json: {},
            circuit: null
        };
    }
    if (returnDestination !== null) {
        end_step = {
            city_name: returnDestination.structured_formatting.main_text,
            country_name: returnDestination.structured_formatting.secondary_text,
            step_type: "END",
            places_id: returnDestination.reference,
            duration_transport_after: 0,
            selected_transport: "",
            distance_transport_km: 0,
            locked_agency: false,
            r2r_api_version: "version",
            r2r_json: {},
            circuit: null
        };
    }
    if (is_custom !== undefined && !is_custom && travelExchangePrices !== undefined && travelExchangePrices !== null) {
        groups.map((group) => {
            if (group.travelers.length === 1) {
                singleRoom++;
            } else if (group.travelers.length === 2) {
                if (group.twin_room && travelExchangePrices.twin_room) {
                    twinRoom++;
                } else {
                    twinShare++;
                }
            } else if (group.travelers.length === 3) {
                tripleRoom++;
            } else {
                quadRoom++;
            }
        });
        url += `&nb_adults=${adults}&nb_children=${children}&single_room=${singleRoom}&triple_room=${tripleRoom}&quad_room=${quadRoom}&twin_share_room=${twinShare}&twin_room=${twinRoom}&travel_exchange_price_terrestrial_id=${travelExchangePrices.id}`;
        if (selectedTravelExchangeOptions !== undefined) {
            for (let i = 0; i < selectedTravelExchangeOptions.length; i++) {
                url += "&travel_exchange_price_terrestrial_option_id=" + selectedTravelExchangeOptions[i];
            }
        }
        if (mealPlan !== null) {
            switch (mealPlan) {
                case t('accommodation_filters.half_board'):
                    url += '&half_board=true';
                    break;
                case t('accommodation_filters.all_inclusive'):
                    url += '&full_board=true';
                    break;
                case t('accommodation_filters.breakfast_included'):
                    url += '&bed_and_breakfast=true';
                    break;
                case t('accommodation_filters.inclusive_board'):
                    url += '&inclusive_board=true';
                    break;
            }
        }
    }
    if (isFrom === 'ItineraryType') {
        getQuotation(destination, parent).then((quotation) => {
            axios({
                method: "POST",
                headers: headers,
                url: url,
                data: {
                    start_step: startDestination !== null ? start_step : undefined,
                    end_step: returnDestination !== null ? end_step : undefined,
                    is_by_day_view: !quotation?.step_by_step,
                    adalte_rooms: adalteRooms,
                    chosen_pickup: pickups,
                    chosen_language_code: adalteLanguageCode
                }
            }).then((response) => {
                let data = Object.assign({}, response.data);
                console.log('data:', data);
                if (quotation_code === "cercledesvoyages") {
                    PatchUserTo(data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms);
                } else {
                    SetDataToRedirect(data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms);
                }
            }).catch((error) => {
                console.log('duplicate error:', error);
            });
        });
    } else {
        axios({
            method: "POST",
            headers: headers,
            url: url,
            data: {
                start_step: startDestination !== null ? start_step : undefined,
                end_step: returnDestination !== null ? end_step : undefined,
                is_by_day_view: is_by_day_view,
                adalte_rooms: adalteRooms,
                chosen_pickup: pickups,
                chosen_language_code: adalteLanguageCode
            }
        }).then((response) => {
            let data = Object.assign({}, response.data);
            console.log('data:', data);
            if (quotation_code === "cercledesvoyages") {
                PatchUserTo(data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms);
            } else {
                SetDataToRedirect(data, tempTravelers, tempContactLead, withApplicant, groups, active_page, trip_list_ordering, trip_list_search, trip_list_filter, flight_circuit, startAirport, cabin, providers, itinerary, user, dispatch, router, enqueueSnackbar, t, adalteRooms);
            }
        }).catch((error) => {
            console.log('duplicate error:', error);
        });
    }
};
export default DuplicateTrip;
