import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';

import HasPermission from "../../Common/Functions/HasPermission.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import SetCookie from "../../Common/Functions/SetCookie";
import GetCookie from "../../Common/Functions/GetCookie";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
}));

const MobileAvatarHeader = ({router, setIsOpen}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permissions = useSelector((store) => {
        return store.user.permissions;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const openVisiteursContact = () => {
        let win = window.open('https://www.visiteurspro.com/pages/a-la-carte', '_blank');
        win.focus();
    }
    let current_location = router.getCurrentLocation();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    return (
        <List>
            {
                (data_trip === null || (data_trip !== null && data_trip.trip !== undefined && !data_trip.trip.itinerary_type && !data_trip.trip.package_type && !data_trip.trip.circuit_type)) && (
                    <>
                        <ListItem button onClick={() => {
                            router.push(`/${window.url_name}/menu/trip-list`);
                            setIsOpen(null);            
                        }} className={`${current_location.pathname.includes("trip-list") ? classes.active : ""}`}>
                            <ListItemIcon>
                                <FolderOpenOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("header.menu.list_trip")} />
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => {router.push(`/${window.url_name}/menu/profile`)}} className={`${current_location.pathname.includes("profile") ? classes.active : ""}`}>
                            <ListItemIcon>
                                <PersonOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("header.menu.profile")} />
                        </ListItem>
                        <Divider/>
                        {
                            quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_client") === true && (
                                <Fragment>
                                    <ListItem button onClick={() => {router.push(`/${window.url_name}/menu/agency-user-management`)}} className={`${current_location.pathname.includes("agency-user-management") ? classes.active : ""}`}>
                                        <ListItemIcon>
                                            <GroupOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t("header.menu.agency_users")} />
                                    </ListItem>
                                    <Divider/>
                                </Fragment>
                            )
                        }
                        {
                            quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_client") === true && (
                                <Fragment>
                                    <ListItem button onClick={() => {router.push(`/${window.url_name}/menu/agency-margin`)}} className={`${current_location.pathname.includes("agency-margin") ? classes.active : ""}`}>
                                        <ListItemIcon>
                                            <StorefrontOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t("header.menu.agency_margin")} />
                                    </ListItem>
                                    <Divider/>
                                </Fragment>
                            )
                        }
                        {
                            quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_user") === true && (
                                <Fragment>
                                    <ListItem button onClick={() => {router.push(`/${window.url_name}/menu/agency-profile`)}} className={`${current_location.pathname.includes("agency-profile") ? classes.active : ""}`}>
                                        <ListItemIcon>
                                            <AccountBalanceIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t("header.menu.agency_profile")} />
                                    </ListItem>
                                    <Divider/>
                                </Fragment>
                            )
                        }
                    </>
                )
            }
            {
                quotation_code === 'visiteurs' && user.client_full.type === 2 &&
                <>
                    <ListItem button onClick={openVisiteursContact}>
                        <ListItemIcon>
                            <AlternateEmailIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('global.visiteurs_contact')} />
                    </ListItem>
                    <Divider/>
                </>
            }
            <ListItem button onClick={() => {
                const { headers } = CheckBeforeRequest();
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/user/access_support/`,
                }).then(function (response) {
                    setIsOpen(null);
                    let win = window.open(response.data, '_blank');
                    win.focus();
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                });
            } }>
                <ListItemIcon>
                    <HelpOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={quotation_code === 'visiteurs' && user.client_full.type === 2 ? t("global.tutorial") : t('global.help')} />
            </ListItem>
            <Divider/>
            <ListItem button onClick={ () => {
                let cookies = document.cookie.split("; ");
                const crmCookies = {
                    crm_token: GetCookie("crm_token"),
                    crm_id_user: GetCookie("crm_id_user"),
                    crm_client_user: GetCookie("crm_client_user"),
                    isFromCrm: GetCookie("isFromCrm")
                };
                for (let c = 0; c < cookies.length; c++) {
                    let d = window.location.hostname.split(".");
                    while (d.length > 0) {
                        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                        let p = location.pathname.split("/");
                        document.cookie = cookieBase + "/";
                        while (p.length > 0) {
                            document.cookie = cookieBase + p.join("/");
                            p.pop();
                        }
                        d.shift();
                    }
                }
                if (crmCookies.crm_token) {
                    for (const key in crmCookies) {
                        SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    }
                }
                setIsOpen(null);
                router.push(`/home`);
                dispatch({
                    type: "USER_SET_CONNECTION",
                    payload: {
                        modal_connection: false,
                        is_connected: false,
                        user: null,
                        token: null
                    }
                });
            } }>
                <ListItemIcon>
                    <ExitToAppOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("header.menu.logout")} />
            </ListItem>
        </List>
    )
}
export default React.memo(MobileAvatarHeader);