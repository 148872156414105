import { useTranslation } from "react-i18next";
import axios, { AxiosError } from "axios";
import { TFunction } from "i18next";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SelectedRoom } from "../../ItineraryType/RoomTypePicker";

type CallbackOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    startDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[],
    travelExchangeOptions?: {
        selectedTravelExchangePrices: any,
        selectedTravelExchangeOptions: any,
        mealPlan: any
    }
}

type Callback = (options: CallbackOptions) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: AxiosError) => void,
    onFinally: () => void
}>

export function useItineraryDuplicateTripProducts(options: Options): Callback {
    const { t } = useTranslation();

    return async (callbackOptions) => {
        if (options.onTrigger) {
            options.onTrigger();
        }
        try {
            await makeRequest({
                sourceTripId: callbackOptions.sourceTripId,
                sourceTripVersion: callbackOptions.sourceTripVersion,
                targetTripVersion: callbackOptions.targetTripVersion,
                tripStartDate: callbackOptions.startDate,
                products: callbackOptions.products,
                adalteRooms: callbackOptions.adalteRooms,
                travelExchangeOptions: callbackOptions.travelExchangeOptions,
                t
            });
            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    tripStartDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[],
    travelExchangeOptions?: {
        selectedTravelExchangePrices: any,
        selectedTravelExchangeOptions: any,
        mealPlan: any
    },
    t: TFunction
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    const url = new URL(
        API_HREF.startsWith('http') ?
            `${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/` :
            `${window.location.origin}${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/`
    );
    for (const id of options.products ?? []) {
        url.searchParams.append('pid', id.toString());
    }
    url.searchParams.append('id', options.sourceTripId.toString());
    url.searchParams.append('version_id', options.sourceTripVersion.toString());
    url.searchParams.append('target_trip_version', options.targetTripVersion.toString());
    url.searchParams.append('start_date', options.tripStartDate.toString());

    if (options.travelExchangeOptions?.mealPlan) {
        switch (options.travelExchangeOptions.mealPlan) {
            case options.t('accommodation_filters.half_board'): {
                url.searchParams.append('half_board', 'true');
                break;
            }
            case options.t('accommodation_filters.all_inclusive'): {
                url.searchParams.append('full_board', 'true');
                break;
            }
            case options.t('accommodation_filters.breakfast_included'): {
                url.searchParams.append('bed_and_breakfast', 'true');
                break;
            }
            case options.t('accommodation_filters.inclusive_board'): {
                url.searchParams.append('inclusive_board', 'true');
                break;
            }
        }
    }

    if (options.travelExchangeOptions?.selectedTravelExchangePrices) {
        url.searchParams.append('travel_exchange_price_terrestrial_id', options.travelExchangeOptions.selectedTravelExchangePrices.id);
    }

    if (options.travelExchangeOptions?.selectedTravelExchangeOptions) {
        for (const item of options.travelExchangeOptions.selectedTravelExchangeOptions) {
            url.searchParams.append('travel_exchange_price_terrestrial_option_id', item);
        }
    }

    if (pass_check) {
        await axios.post(
            url.toString(),
            { adalte_rooms: options.adalteRooms },
            { headers }
        );
    }
}
